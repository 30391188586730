import { useState, useContext } from "react";
import Modal from "antd/lib/modal/Modal";
import AppAlert from "../Alert/AppAlert";

import cancelButton from "../../assets/cancel.svg";

import "../TransferModal/TransferModal.scss";
import { Input, Button } from "antd";
import { userContext } from "../../store/appContext";
import {
  fetchMerchantBalance,
  fetchMerchantPayouts,
  merchantLogin,
  walletWithdraw,
} from "../../services/apiSevices";
import Password from "antd/lib/input/Password";
import { useShowAlert } from "../../hooks/useShowAlert";

const WithdrawModal = ({ isWithdrawModalOpen, setIsWithdrawModalOpen }) => {
  const context = useContext(userContext);
  const { currentMerchant, updateBalance, updatePayouts, user } = context;
  const {showAlert, openAlert} = useShowAlert()
  const [step, setStep] = useState(1);
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const withdraw = async () => {
    let logindata = {
      email: user.email,
      password,
    };
    try {
      if (password === "") {
        return;
      }
      setLoading(true);
      const loginStatus = await merchantLogin(logindata);
      if (loginStatus.status) {
        let data = {
          amount: withdrawalAmount,
        };
        const res = await walletWithdraw(currentMerchant.publickey, {
          ...data,
        });
        if (res.status) {
          openAlert("true", "success", "Withdrawal Successful");
          let result = await fetchMerchantBalance(currentMerchant.businessid);
          updateBalance(result.data.balance);
          setLoading(false);
          setWithdrawalAmount("");
          setPassword("");
          setIsWithdrawModalOpen(false);
          setStep(1);
        }
        const result = await fetchMerchantPayouts(currentMerchant.businessid);
        updatePayouts(result.data.data);
      }
    } catch (error) {
      // console.log(error.response.data.message)
      openAlert(true, "error", error?.response?.data.message);
      setLoading(false);
    }
  };

  return (
    <>
      <AppAlert
        show={showAlert.show}
        type={showAlert.type}
        description={showAlert.description}
      />
      <Modal
        visible={isWithdrawModalOpen}
        closable={false}
        footer={false}
        onCancel={() => {
          setIsWithdrawModalOpen(false);
          setStep(1);
          setPassword("");
          setWithdrawalAmount("");
        }}
      >
        {step === 1 && (
          <div className="step-wrapper">
            <div className="title-wrapper">
              <h4 className="header-text">How much do you want to withdraw</h4>
              <img
                src={cancelButton}
                alt=""
                onClick={() => {
                  setIsWithdrawModalOpen(false);
                }}
              />
            </div>
            <div className="row-con">
              <p
                className="label"
                style={{ margin: "10px 0px", color: "rgba(9,66,125,0.3)" }}
              >
                Amount
              </p>
              <Input
                // disabled={bankDataVerified}
                bordered={false}
                style={{ height: 55, width: "100%" }}
                type="number"
                value={withdrawalAmount}
                onChange={(e) => {
                  setWithdrawalAmount(e.target.value);
                }}
              />
            </div>
            <Button
              className="dark-btn"
              style={{
                width: "100%",
                height: 50,
                borderRadius: 5,
                margin: "20px 0px 10px 0px",
                fontSize: 17,
                fontWeight: "bold",
              }}
              onClick={() => {
                withdrawalAmount && setStep(2);
              }}
              // loading={loading}
            >
              Continue
            </Button>
          </div>
        )}
        {step === 2 && (
          <div className="step-wrapper">
            <div className="title-wrapper">
              <h4 className="header-text">
                Please enter your password to authorise payment
              </h4>
              <img
                src={cancelButton}
                alt=""
                onClick={() => {
                  setIsWithdrawModalOpen(false);
                  setStep(1);
                }}
              />
            </div>
            <div className="row-con">
              <p
                className="label"
                style={{ margin: "10px 0px", color: "rgba(9,66,125,0.3)" }}
              >
                Password
              </p>
              <Password
                // disabled={bankDataVerified}
                bordered={false}
                style={{ height: 55, width: "100%" }}
                value={password}
                autoComplete="false"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <Button
              className="dark-btn"
              style={{
                width: "100%",
                height: 50,
                borderRadius: 5,
                margin: "20px 0px 10px 0px",
                fontSize: 17,
                fontWeight: "bold",
              }}
              onClick={withdraw}
              loading={loading}
            >
              Authorize Payment
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default WithdrawModal;
