import {
  CaretDownFilled,
  CaretUpFilled,
  MailFilled,
  PlusOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";

import "./Dashhome.scss";
import { Col, Dropdown, Empty, Menu, Modal, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";

import userIcon from "../../../assets/user.svg";
import BarChart from "../../../components/Chart/BarChart";
import LineChart from "../../../components/Chart/LineChart";
import HomeCard from "../../../components/Cards/HomeCard";
import { userContext } from "../../../store/appContext";
import {
  fetchMerchant,
  fetchMerchantBalance,
  fetchMerchantCashpoints,
  fetchMerchantCustomers,
  fetchMerchantPayouts,
  fetchMerchantTransactions,
  fetchTranferFees,
  getCurrentUser,
} from "../../../services/apiSevices";

import * as dayjs from "dayjs";
import { formatNumber } from "../../../utils/helpers";
import Registeration from "../../../components/RegisterForm/Registeration";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

export default withRouter(function DashboardHome({ history }) {
  const context = useContext(userContext);
  const {
    transactions,
    customers,
    user,
    walletBalance,
    updateTransactions,
    updateCustomers,
    updateCashpoints,
    updateUser,
    currentMerchant,
    updateCurrentMerchant,
    updatePayouts,
    updateBalance,
    updateActiveIndex,
    updateCurrentPage,
    updateTransactionInboundFee,
    updateTransactionOutboundFee,
  } = context;

  const [tnxTotal, setTnxTotal] = useState(0);
  const [todayTnxTotal, setTodayTnxTotal] = useState(0);
  const [barData, setBarData] = useState({ labels: [], data: [] });
  const [lineData, setLineData] = useState({ labels: [], data: [] });
  const [currentID, setCurrentID] = useState(currentMerchant.businessid);
  const [openModal, setOpenModal] = useState(false);

  const state2 = {
    labels: lineData.labels,
    datasets: [
      {
        backgroundColor: "#FFB4E4",
        fill: false,
        borderColor: "#790E53",
        lineTension: 0,
        borderWidth: 0,
        pointRadius: 0,
        data: lineData.data,
      },
    ],
  };
  const state = {
    labels: barData.labels,
    datasets: [
      {
        backgroundColor: "#B6E2D7",
        borderWidth: 0,
        data: barData.data,
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchTranferFees(
          currentMerchant.publickey,
          currentMerchant.businessid
        );
        const merchantData = await fetchMerchant(currentID);
        const { data } = res.data;
        const classification = merchantData.data.classification;
        updateTransactionInboundFee(data[classification].inbound.fee);
        updateTransactionOutboundFee(data[classification].outbound.fee);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (transactions) {
      let total = transactions.reduce((cumm, curr) => (cumm += curr.amount), 0);
      let todayTnx = transactions.map((tnx) => {
        return {
          ...tnx,
          isToday:
            dayjs(tnx.createdon).format("DD MMM YY") ===
            dayjs().format("DD MMM YY"),
        };
      });

      let todayTotal = todayTnx
        .filter((t) => t.isToday)
        .reduce((cumm, curr) => (cumm += curr.amount), 0);
      setTodayTnxTotal(todayTotal);
      setTnxTotal(total);

      let transactions_ = transactions
        .map((c) => {
          return {
            ...c,
            date: dayjs(c.createdon).format("MMM"),
          };
        })
        .reverse()
        .reduce((cumm, curr) => {
          cumm[curr.date]
            ? (cumm[curr.date] = cumm[curr.date] + curr.amount)
            : (cumm[curr.date] = curr.amount);
          return cumm;
        }, {});

      let barLabelData =
        Object.keys(transactions_).length === 1
          ? [
              dayjs(transactions[0].createdon)
                .subtract(1, "month")
                .format("MMM"),
              dayjs(transactions[0].createdon)
                .subtract(2, "month")
                .format("MMM"),
              ...Object.keys(transactions_),
            ]
          : Object.keys(transactions_);

      let barDataArray =
        Object.keys(transactions_).length === 1
          ? [0, 0, ...Object.values(transactions_)]
          : Object.values(transactions_);

      setBarData({
        labels: barLabelData,
        data: barDataArray,
      });
    }
  }, [transactions]);

  useEffect(() => {
    if (customers) {
      let customers_ = customers
        .map((c) => {
          return {
            ...c,
            date: dayjs(c.createdon).format("MMM"),
          };
        })
        .reverse()
        .reduce((cumm, curr) => {
          cumm[curr.date]
            ? (cumm[curr.date] = cumm[curr.date] + 1)
            : (cumm[curr.date] = 1);
          return cumm;
        }, {});

      let lineLabelsArray =
        Object.keys(customers_).length === 1
          ? [
              dayjs(customers[0].createdon).subtract(2, "month").format("MMM"),
              dayjs(customers[0].createdon).subtract(1, "month").format("MMM"),
              ...Object.keys(customers_),
            ]
          : Object.keys(customers_);

      let lineDataArray =
        Object.keys(customers_).length === 1
          ? [0, 0, ...Object.values(customers_)]
          : Object.values(customers_);

      setLineData({
        labels: lineLabelsArray,
        data: lineDataArray,
      });
    }
  }, [customers]);

  useEffect(() => {
    if (currentMerchant) {
      fetchData();
      fetchUserMerchants();
    }
  }, [currentID]);

  const fetchData = () => {
    fetchMerchantCashpoints(currentID)
      .then((res) => {
        updateCashpoints(res.data);
      })
      .catch((err) => {
        updateCashpoints([]);
        console.log(err);
      });

    fetchMerchantBalance(currentID)
      .then((res) => {
        updateBalance(res.data.balance);
      })
      .catch((err) => {
        updateBalance(0);
        console.log(err);
      });

    fetchMerchantPayouts(currentID)
      .then((res) => {
        updatePayouts(res.data.data);
      })
      .catch((err) => {
        updatePayouts([]);
        console.log(err);
      });

    fetchMerchantCustomers(currentID)
      .then((res) => {
        updateCustomers(res.data);
      })
      .catch((err) => {
        console.log(err);
        updateCustomers([]);
      });

    fetchMerchantTransactions(currentID)
      .then((res) => {
        updateTransactions(res.data);
      })
      .catch((err) => {
        updateTransactions([]);
        console.log(err);
      });

    fetchMerchant(currentID)
      .then((res) => {
        updateCurrentMerchant({ ...currentMerchant, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchUserMerchants = () => {
    getCurrentUser(currentMerchant.businessid).then((res) => {
      updateUser({
        ...user,
        merchants: res.data.merchants,
      });
    });
  };

  return (
    <div className="dash-home">
      <Row gutter={20}>
        <Col xs={24} md={6} style={{ marginBottom: 12 }}>
          <HomeCard
            color={"#0a427d"}
            heading="Ledger Balance"
            value={`₦${formatNumber(walletBalance)}`}
          />
          <ArrowUpOutlined
            onClick={() => {
              history.push("/dashboard/wallet");
              updateCurrentPage("Wallet");
              updateActiveIndex(2);
            }}
            style={{
              position: "absolute",
              top: 18,
              color: "white",
              zIndex: 120,
              fontSize: "2em",
              right: 30,
              cursor: "pointer",
              transform: "rotate(50deg)",
            }}
          />
        </Col>
        <Col xs={24} md={6} style={{ marginBottom: 12 }}>
          <HomeCard
            color={"#03624b"}
            heading="Order Transactions"
            value={`₦${formatNumber(tnxTotal)}`}
          />
        </Col>
        <Col xs={24} md={6} style={{ marginBottom: 12 }}>
          <HomeCard
            color={"#dcf4ef"}
            heading="Customers"
            darkText
            value={customers ? customers.length : 0}
          />
        </Col>
        <Col xs={24} md={6} style={{ marginBottom: 12 }}>
          <div className="vpay-card-big">
            <div className="top">
              <div className="left">
                <p className="hello">
                  <span className="txt">Hello,</span>
                  <span style={{ textTransform: "capitalize" }}>
                    {currentMerchant.contactfirstname}
                  </span>
                </p>
                <span className="url">
                  dartpay.cash/{currentMerchant.businessname.replace(/ /g, "")}
                </span>
              </div>
              <div className="right">
                <img src={userIcon} alt="" />
              </div>
            </div>

            <Dropdown
              overlay={
                <Menu>
                  {user.merchants.map((val, i) => (
                    <Menu.Item
                      key={i}
                      onClick={(e) => {
                        updateCurrentMerchant(val);
                        setCurrentID(val.businessid);

                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      }}
                    >
                      {val.businessname} ({val.shortid})
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <div className="gradient">
                <span className="bg">{currentMerchant.businessname}</span>
                <span className="sm">
                  Business ID:
                  <span style={{ fontSize: 14, marginLeft: 4 }}>
                    {currentMerchant.shortid}
                  </span>
                  <div
                    className="carets"
                    style={{
                      float: "right",
                      display: "flex",
                      flexDirection: "column",
                      marginTop: -10,
                    }}
                  >
                    <CaretUpFilled style={{ fontSize: 10, color: "#fff" }} />
                    <CaretDownFilled style={{ fontSize: 10, color: "#fff" }} />
                  </div>
                </span>
              </div>
            </Dropdown>

            <div className="add-merchant" onClick={() => setOpenModal(true)}>
              <PlusOutlined style={{ marginRight: 5, fontSize: 16 }} /> Add
              Merchant
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={24} md={12} style={{ marginBottom: 30 }}>
          <div className="white-bg pull-up" style={{ maxHeight: 600 }}>
            <div className="chart-heading">
              <div>
                <p className="rev bw-b">Today's Revenue</p>
                <p className="amt">₦{formatNumber(todayTnxTotal)}</p>
              </div>
              {/* 
              <Dropdown
                overlay={
                  <Menu>
                    {filters.map((val) => (
                      <Menu.Item onClick={(e) => setCurrentFilter(val)}>
                        {val}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <div className='calendar'>
                  <CalendarOutlined />
                  <span style={{ padding: "0 10px", fontSize: 12 }}>
                    {currentFilter}
                  </span>
                  <CaretDownFilled />
                </div>
              </Dropdown> */}
            </div>
            {transactions && transactions.length ? (
              <BarChart state={state} />
            ) : (
              <Empty />
            )}
          </div>
        </Col>
        <Col xs={24} md={6}>
          <div className="white-bg pull-up" style={{ maxHeight: 600 }}>
            <div className="chart-heading">
              <div>
                <p className="rev bw-b">Customer Growth</p>
              </div>
              {/* <div className='calendar'>
                <CalendarOutlined />
              </div> */}
            </div>
            {customers && customers.length ? (
              <LineChart state={state2} />
            ) : (
              <Empty />
            )}
          </div>
        </Col>
        <Col xs={24} md={6}>
          <div className="white-bg" style={{ maxHeight: 550, minHeight: 380 }}>
            <div className="chart-heading">
              <div>
                <p className="rev bw-b">Notifications</p>
              </div>
              {/* <div className='view-all bw-b'>
                View All <RightOutlined style={{ marginLeft: 5 }} />
              </div> */}
            </div>
            <div className="notifications">
              <Empty />
              {[].map((notif) => (
                <div className="notification">
                  <div className="mail">
                    <MailFilled />
                  </div>
                  <div className="preview">
                    <div className="title bm-b">Booking Reminder</div>
                    <div className="text bw-m">
                      Something really nice is going on here
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        title="Add Merchant"
        visible={openModal}
        footer={false}
        closable={true}
        onCancel={() => setOpenModal(false)}
        centered={true}
      >
        <Registeration
          newUser={false}
          finishReg={() => {
            setOpenModal(false);
            fetchUserMerchants();
          }}
        />
      </Modal>
    </div>
  );
});
