import React, { createContext, Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Axios from "axios";
import AppAlert from "../components/Alert/AppAlert";
export const userContext = createContext();

export default withRouter(
  class AppContextProvider extends Component {
    state = {
      openAlert: false,
      activeIndex: Number(sessionStorage.getItem("activeIndex")) || 0,
      showCaution: false,
      currentPage: sessionStorage.getItem("currentPage") || "Dashboard",
      loading: false,
      walletBalance: 0,
      transactionInboundFee: "",
      transactionOutboundFee: "",
      user: {},
      beneficiary: [],
      transactions: [],
      cashpoints: [],
      customers: [],
      currentMerchant: {},
      options: [
        "Advertising Agencies",
        "Airlines",
        "Apparel Retailers",
        "Automobile/Auto Part Retailers",
        "Automobiles/Auto Parts",
        "Banking",
        "Beverages--Brewers/Distillers",
        "Beverages--Non-Alcoholic",
        "Building Construction",
        "Building Materials",
        "Building Structure/Completion",
        "Chemicals",
        "Coal Extraction",
        "Coal and Coal Products Distributors",
        "Computer Based Systems",
        "Computer Software",
        "Computers and Peripherals",
        "Consumer Electronics",
        "Cosmetics",
        "Courier/Freight/Delivery",
        "Crop Production",
        "Crude Oil and Natural Gas Extraction",
        "Diversified Communication Services",
        "Education/Training",
        "Electric Power Distribution",
        "Electric Power Generation",
        "Electric Power Transmission",
        "Electronic Office Equipment",
        "Electronic and Electrical Products",
        "Electronics/Appliances Retailers",
        "Employment Solutions",
        "Energy Equipment and Services",
        "Fashion/Clothing",
        "Field Services",
        "Fishing/Hunting/Trapping",
        "Food Products",
        "Food Products--Diversified",
        "Food/Drug Retailers and Wholesalers",
        "Gasoline Stations",
        "Healthcare Providers",
        "Hospitality",
        "Hotels/Lodging",
        "Household Durables",
        "IT Services",
        "Insurance Carriers, Brokers and Services",
        "Integrated Oil and Gas Services",
        "Internet Service Providers",
        "Livestock/Animal Specialties",
        "Media/Entertainment",
        "Medical Equipment",
        "Medical Supplies",
        "Metals",
        "Mining Services",
        "Miscellaneous Services",
        "Mortgage Carriers, Brokers and Services",
        "Non-Building/Heavy Construction",
        "Non-Depository Credit Institutions",
        "Non-Metallic Mineral Mining",
        "Other Financial Institutions",
        "Other ICT Products and Services",
        "Other Services",
        "Packaging/Containers",
        "Paper/Forest Products",
        "Personal/Household Products",
        "Petroleum Bulk Stations and Terminals",
        "Petroleum Refining",
        "Petroleum and Petroleum Products Distributors",
        "Pharmaceuticals",
        "Precious Metals",
        "Precious Stones",
        "Printing/Publishing",
        "Processing Systems",
        "Property Management",
        "Rail Transportation",
        "Real Estate Development",
        "Real Estate Investment Trusts(REITs)",
        "Repair/Maintenance",
        "Road Transportation",
        "Scientific and Technical Instruments",
        "Semiconductors",
        "Site Preparation Services",
        "Specialty Retailers",
        "Storage/Warehousing",
        "Telecommunications Carriers",
        "Telecommunications Equipment",
        "Telecommunications Services",
        "Textiles/Apparel",
        "Tobacco Products",
        "Tools and Machinery",
        "Toys and Games",
        "Transport-Related Services",
        "Travel and Tourism",
        "Waste Management",
        "Water Transportation",
        "Water Treatment and Distribution",
      ],
      banks: [],
      payouts: [],
    };

    componentDidMount() {
      // interceptor to log users with expired tokens out
      Axios.interceptors.response.use(
        function (res) {
          return res;
        },
        (err) => {
          if (
            err.response &&
            err.response.data.message === "Token expired." &&
            err.response.status === 403
          ) {
            this.setState({ openAlert: true });
            setTimeout(() => {
              this.setState({ openAlert: false });
            }, 6000);
            this.props.history.push("/login");
          }
          return Promise.reject(err);
        }
      );
    }

    componentWillMount() {
      let state = JSON.parse(sessionStorage.getItem("parentValueKey"));
      this.setState(state);
      // let token = this.state.user.token || "";
      // axios.defaults.headers.common["Authorization"] = `${token}`;
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state !== prevState) {
        // Whatever storage mechanism you end up deciding to use.
        sessionStorage.setItem("parentValueKey", JSON.stringify(this.state));
      } else {
        sessionStorage.setItem("parentValueKey", JSON.stringify(prevState));
      }
    }

    updateLoader = (loading) => {
      this.setState({ loading });
    };
    updateBeneficiary = (beneficiary) => this.setState({ beneficiary });
    updateShowCaution = (showCaution) => this.setState({ showCaution });
    updateUser = (user) => this.setState({ user });
    updatePayouts = (payouts) => this.setState({ payouts });
    updateTransactionInboundFee = (transactionInboundFee) =>
      this.setState({ transactionInboundFee });
    updateTransactionOutboundFee = (transactionOutboundFee) =>
      this.setState({ transactionOutboundFee });
    updateBanks = (banks) => this.setState({ banks });
    updateCustomers = (customers) => this.setState({ customers });
    updateTransactions = (transactions) => this.setState({ transactions });
    updateCashpoints = (cashpoints) => this.setState({ cashpoints });
    updateCurrentPage = (currentPage) => this.setState({ currentPage });
    updateBalance = (walletBalance) => this.setState({ walletBalance });
    updateActiveIndex = (activeIndex) => this.setState({ activeIndex });
    updateCurrentMerchant = (currentMerchant) =>
      this.setState({ currentMerchant });

    logoutState = () => {
      this.setState({
        openAlert: false,
        loading: false,
        user: {},
        transactions: [],
        cashpoints: [],
        customers: [],
        currentMerchant: {},
        walletBalance: 0,
        showCaution: false,
        currentPage: "Dashboard",
        activeIndex: 0,
      });
    };

    render() {
      const { openAlert } = this.state;
      const {
        updateLoader,
        updateUser,
        updateCustomers,
        updateTransactions,
        updateCashpoints,
        logoutState,
        updateCurrentMerchant,
        updateBanks,
        updatePayouts,
        updateBalance,
        updateShowCaution,
        updateActiveIndex,
        updateBeneficiary,
        updateCurrentPage,
        updateTransactionInboundFee,
        updateTransactionOutboundFee,
      } = this;
      return (
        <userContext.Provider
          value={{
            ...this.state,
            updateLoader,
            updateUser,
            updateCustomers,
            updateTransactions,
            updateCashpoints,
            logoutState,
            updateCurrentMerchant,
            updateBanks,
            updatePayouts,
            updateBalance,
            updateShowCaution,
            updateActiveIndex,
            updateBeneficiary,
            updateCurrentPage,
            updateTransactionInboundFee,
            updateTransactionOutboundFee,
          }}
        >
          <AppAlert
            show={openAlert}
            type={"info"}
            description="Your session has expired. Kindly login to continue"
          />
          {this.props.children}
        </userContext.Provider>
      );
    }
  }
);
