import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import uuid from "react-uuid";
import { Button, Modal, Radio, Divider } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import cancelButton from "../../assets/cancel.svg";
import successSign from "../../assets/succesSign.svg";
import searchIcon from "../../assets/searchIcon.svg";

import "./TransferModal.scss";
import { userContext } from "../../store/appContext";
import TransferForm from "../ModalSteps/TransferForm";
import {
  verifyBankDetails,
  addBeneficiary,
  walletTransfer,
  merchantLogin,
  fetchWalletPrefix,
} from "../../services/apiSevices";
import AppAlert from "../Alert/AppAlert";
import { formatNumber } from "../../utils/helpers";
import Password from "antd/lib/input/Password";
import { useShowAlert } from "../../hooks/useShowAlert";

export default function TransferModal({
  isTransferModalOpen,
  setIsTransferModalOpen,
  transactionOutboundFee,
}) {
  const history = useHistory();
  const context = useContext(userContext);
  const {
    banks,
    updateActiveIndex,
    beneficiary,
    currentMerchant,
    user,
  } = context;
  const {showAlert, openAlert} = useShowAlert()
  const [step, setStep] = useState(1);
  const [radioValue, setRadioValue] = useState("NEW");
  const [searchField, setSearchField] = useState("");
  const [bankDataVerified, setbankDataVerified] = useState(false);
  const [bankName, setbankName] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [flag, setFlag] = useState(true);
  const [walletPrefixRef, setWalletPrefixRef] = useState("");
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [isTransferFailure, setIsTransferFailure] = useState(false);

  const [saveBenefiary, setSaveBenficiary] = useState("");
  const [password, setPassword] = useState("");

  const [transferData, setTransferData] = useState({
    bankcode: "",
    accountnumber: "",
    amount: "",
    description: "",
  });

  useEffect(() => {
    let cleanup = true;
    if(cleanup){
      const fetchData = async () => {
        const res = await fetchWalletPrefix(
          currentMerchant.publickey,
          currentMerchant.businessid
        );
        setWalletPrefixRef(res.data.data);
      };
      fetchData();
    }
    return () => cleanup = false;
  }, [
    isTransferFailure,
    isTransferSuccess,
    currentMerchant.publickey,
    currentMerchant.businessid,
  ]);

  const transferMoney = async () => {
    let logindata = {
      email: user.email,
      password,
    };
    try {
      if (password === "") {
        return;
      }

      setLoading(true);
      const loginStatus = await merchantLogin(logindata);
      if (loginStatus.status) {
        let data = {
          nuban: transferData.accountnumber,
          bank_code: transferData.bankcode.split("|")[0],
          amount: transferData.amount,
          remark: transferData.description,
          transaction_ref: walletPrefixRef?.concat(uuid()),
        };
        let beneficiaryData = {
          accountnumber: transferData.accountnumber,
          accountname: bankName,
          bankcode: transferData.bankcode.split("|")[0],
          merchant: currentMerchant.businessid,
        };
        if (saveBenefiary === "yes") {
          await addBeneficiary(currentMerchant.publickey, beneficiaryData);
        }
        if (walletPrefixRef === undefined) {
          openAlert(true, "error", "Transaction prefix ref not Found");
          setLoading(false);
          return;
        }
        await walletTransfer(currentMerchant.publickey, data);
        setLoading(false);
        setTransferData({
          bankcode: "",
          accountnumber: "",
          amount: "",
          description: "",
        });
        setPassword("");
        setStep(4);
        setIsTransferSuccess(true);
      }
    } catch (error) {
      setPassword("");
      setLoading(false);
      setIsTransferFailure(true);
      openAlert(true, "error", error?.response?.data.message);
    }
  };

  const onSearchChange = (e) => {
    setSearchField(e);
  };

  const handleBeneficiaryClick = (beneficiary) => {
    setTransferData({
      ...transferData,
      bankcode: beneficiary.code + "|" + getBankName(beneficiary.code),
      accountnumber: beneficiary.number,
    });
    setbankName(beneficiary.name);
    setRadioValue("NEW");
    setbankDataVerified(true);
    setFlag(false);
    setSearchText("");
  };

  const handleModalCancel = () => {
    setRadioValue("NEW");
    setIsTransferModalOpen(false);
    setStep(1);
    setFlag("");
    setPassword("");
    setbankDataVerified(false);
    setTransferData({
      bankcode: "",
      accountnumber: "",
      amount: "",
      description: "",
    });
  };

  const filteredBanks = banks.filter((bank) =>
    bank.name.toLowerCase().includes(searchField.toLowerCase())
  );

  const verifyData = () => {
    if (
      transferData.accountnumber &&
      transferData.bankcode &&
      transferData.bankcode !== "Select Bank"
    ) {
      transferData.bankcode = transferData.bankcode.split("|")[0];
      let data = {
        accountnumber: transferData.accountnumber,
        bankcode: transferData.bankcode,
      };
      setLoading(true);
      verifyBankDetails(data)
        .then((res) => {
          if (res.data) {
            setbankName(res.data.accountname);
            setbankDataVerified(true);
            setFlag(true);
            setLoading(false);
            openAlert(true, "success", "Bank details verified");
          }
        })
        .catch((err) => {
          openAlert(true, "error", (err.response || {}).data.message);
          setLoading(false);
          // console.log(err.response, err);
        });
    }
  };
  const getBankName = (code) => {
    let foundBank = banks.find((bank) => bank.code === code);
    return foundBank ? foundBank.name : "";
  };

  const handleBackClick = () => {
    setStep((step) => step - 1);
  };

  const handleTabChange = (e) => {
    setRadioValue(e.target.value);
  };

  const filteredBeneficiary = beneficiary?.filter((ben) =>
    ben.name.toLowerCase().includes(searchText.toLowerCase())
  );
  return (
    <>
      <AppAlert
        show={showAlert.show}
        type={showAlert.type}
        description={showAlert.description}
      />
      <Modal
        visible={isTransferModalOpen}
        closable={false}
        footer={false}
        onCancel={handleModalCancel}
      >
        {step === 1 && (
          <StepWrapper>
            <TitleWrapper
              headerText="Transfer Money"
              onClick={() => {
                setIsTransferModalOpen(false);
                setRadioValue("NEW");
              }}
            />
            <RadioGroup
              value={radioValue}
              onChange={handleTabChange}
              onClick={() => {
                setTransferData({
                  bankcode: "",
                  accountnumber: "",
                  amount: "",
                  description: "",
                });
                setFlag(true);
                setbankDataVerified(false);
              }}
            />
            {radioValue === "NEW" ? (
              <TransferForm
                onSearchChange={onSearchChange}
                filteredBanks={filteredBanks}
                setTransferData={setTransferData}
                transferData={transferData}
                verifyData={verifyData}
                loading={loading}
                bankDataVerified={bankDataVerified}
                setbankDataVerified={setbankDataVerified}
                bankName={bankName}
                getBankName={getBankName}
                setStep={setStep}
                setSaveBenefiary={setSaveBenficiary}
                flag={flag}
              />
            ) : (
              <div>
                <SearchWrapper
                  value={searchText}
                  image={searchIcon}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <BeneficiaryWrapper
                  beneficiaries={filteredBeneficiary}
                  handleBeneficiaryClick={handleBeneficiaryClick}
                  getBankName={getBankName}
                />
              </div>
            )}
          </StepWrapper>
        )}
        {step === 2 && (
          <StepWrapper>
            <TitleWrapper
              headerText="Confirm"
              onClick={handleModalCancel}
              showArrow
              handleBackClick={handleBackClick}
            />
            <ConfirmDetailsWrapper
              formatNumber={formatNumber}
              transferData={transferData}
              getBankName={getBankName}
              transactionOutboundFee={transactionOutboundFee}
              bankName={bankName}
            />
            <ButtonWrapper
              text="Transfer Funds"
              num={70}
              onClick={() => setStep(3)}
            />
          </StepWrapper>
        )}
        {step === 3 && (
          <StepWrapper>
            <TitleWrapper
              headerText="Please enter your password to authorise payment"
              onClick={handleModalCancel}
            />
            <div className="row-con">
              <p className="label">Password</p>
              <Password
                bordered={false}
                style={{ height: 55, width: "100%" }}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <ButtonWrapper
              text="Authorize Payment"
              num={40}
              onClick={transferMoney}
              loading={loading}
            />
          </StepWrapper>
        )}

        {step === 4 && (
          <div className="step-wrapper">
            <div className="title-wrapper await">
              <h4 className="header-text">Transfer Successful</h4>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={successSign} alt="" />
            </div>
            <p style={{ textAlign: "center" }}>
              Congratulations, your transaction is successful. Please keep using
              our app for a seamless payment experience.
            </p>
            <ButtonWrapper
              text="Back to Home"
              num={40}
              onClick={() => {
                setIsTransferModalOpen(false);
                history.push("/dashboard");
                setStep(1);
                updateActiveIndex(0);
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
}

export const StepWrapper = ({ children }) => (
  <div className="step-wrapper">{children}</div>
);

export const TitleWrapper = ({
  headerText,
  onClick,
  showArrow,
  handleBackClick,
}) => (
  <div className="title-wrapper">
    {showArrow && <ArrowLeftOutlined onClick={handleBackClick} />}
    <h4 className="header-text">{headerText}</h4>
    <img src={cancelButton} alt="cancel button" onClick={onClick} />
  </div>
);

export const ButtonWrapper = ({ text, num, loading, onClick }) => (
  <>
    <Button
      className="dark-btn"
      style={{
        width: "100%",
        height: 50,
        borderRadius: 5,
        margin: `${num}px 0px 20px 0px`,
        fontSize: 17,
        fontWeight: "bold",
      }}
      onClick={onClick}
      loading={loading}
    >
      {text}
    </Button>
  </>
);

export const RadioGroup = ({ value, onChange, onClick }) => (
  <>
    <Radio.Group defaultValue={value} buttonStyle="solid" onChange={onChange}>
      <Radio.Button value="SAVED">Saved Beneficiary</Radio.Button>
      <Radio.Button value="NEW" onClick={onClick}>
        New Beneficiary
      </Radio.Button>
    </Radio.Group>
  </>
);

export const SearchWrapper = ({ image, value, onChange }) => (
  <div className="search-wrapper">
    <img className="searchIcon" src={image} alt="Search" />
    <input
      type="search"
      placeholder="Search beneficiary"
      value={value}
      onChange={onChange}
    />
  </div>
);

export const BeneficiaryWrapper = ({
  beneficiaries,
  handleBeneficiaryClick,
  getBankName,
}) => {
  return (
    <div className="beneficiary-wrapper">
      {beneficiaries?.map((beneficiary) => (
        <Beneficiary
          key={beneficiary.number}
          beneficiary={beneficiary}
          getBankName={getBankName}
          handleBeneficiaryClick={handleBeneficiaryClick}
        />
      ))}
    </div>
  );
};

export const Beneficiary = ({
  handleBeneficiaryClick,
  beneficiary,
  getBankName,
}) => (
  <div
    style={{
      color: "#09427D",
      marginTop: 20,
      cursor: "pointer",
    }}
    onClick={() => {
      handleBeneficiaryClick(beneficiary);
    }}
  >
    <p>{beneficiary.name}</p>
    <p>{getBankName(beneficiary.code)}</p>
    <p>{beneficiary.number}</p>
    <Divider />
  </div>
);

export const ConfirmDetailsWrapper = ({
  formatNumber,
  transferData,
  getBankName,
  transactionOutboundFee,
  bankName,
}) => (
  <div className="confirm_details-wrapper">
    <div className="details">
      <span>Send</span>
      <p>N{formatNumber(transferData.amount)}</p>
    </div>
    <Divider orientation="left" plain>
      <span>to</span>
    </Divider>

    <div className="details">
      <p>{bankName}</p>
      <p>
        {
          (
            transferData.bankcode +
            "|" +
            getBankName(transferData.bankcode)
          ).split("|")[1]
        }
      </p>
      <p>{transferData.accountnumber}</p>
    </div>
    <Divider />
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <p>Transaction Fee</p>
      <p>N{formatNumber(transactionOutboundFee)}</p>
    </div>
  </div>
);
