import { Alert } from "antd";

export default function AppAlert({ type, description, show }) {
  //   const [open, setopen] = useState(show);
  //   useEffect(() => {
  //     console.log({ open });
  //     setTimeout(() => {
  //       setopen(false);
  //     }, 6000);
  //   }, [open]);
  return (
    <>
      {show && (
        <div
          style={{
            position: "fixed",
            top: 20,
            right: 20,
            zIndex: "123467654345",
            width: 400,
          }}
        >
          <Alert
            message={type}
            description={description}
            type={type}
            showIcon
          />
        </div>
      )}
    </>
  );
}
