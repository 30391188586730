import _ from 'lodash'

export const formatNumber = (number) => {
  return parseFloat(number).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};


export const lastPointCustomer = _.memoize(function(pointCustomers){
   return pointCustomers.find((pointCustomer) => pointCustomer.paymentstatus !== "pending")
})