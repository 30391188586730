import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

export default withRouter(function AuthGuard({ history, children }) {
  const [token, setToken] = useState(sessionStorage.getItem("v_pay_token"));
  useEffect(() => {
    let token = sessionStorage.getItem("v_pay_token");
    if (!token) {
      history.push("/login");
    }
  });
  return <>{token ? children : null}</>;
});
