import React, { useEffect, useState } from "react";

export default function HomeCard({
  children,
  color,
  heading,
  value,
  darkText,
}) {
  const [loading, setloading] = useState(false);
  useEffect(() => {
    value === null ? setloading(true) : setloading(false);
  }, [value]);

  return (
    <div className='vpay-card'>
      <div className='bg'></div>
      <div className='overlay' style={{ backgroundColor: color }}></div>
      <div className='card-texts'>
        <p className='small' style={{ color: darkText ? "#114781" : "#fff" }}>
          {heading}
        </p>
        <p className='big' style={{ color: darkText ? "#114781" : "#fff" }}>
          {value || 0}
        </p>
      </div>
    </div>
  );
}
