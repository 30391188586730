import React from 'react'
import {Link} from 'react-router-dom'

import Footer from '../../components/Footer'

import logo from '../../assets/logoIcon.svg'

import './AboutUs.scss'

export default function AboutUs(){


     return(
        <div className='home'>
          <div className='nav'>
          <div className='logo'>
            <Link to="/">
             <img src={logo} alt='' />
            <span className='bw-b'>
              Dart <span className='green'>Pay</span>
            </span>
            </Link>
          </div>
          <div className='links'>
            <div className='link bw-m'>
              <Link to='/login'>
                <span style={{ color: "#000000" }}>Login</span>
              </Link>
            </div>
            <div className='link'>
              <Link to='/signup'>
                <button className='sm-btn green-btn'>Register</button>
              </Link>
            </div>
          </div>
        </div>
          <div className="tc-title">
             <h2>About DartPay</h2>
          </div>
          <div className="about-content">
            <strong>DartPay</strong> helps cashiers accept and confirm bank transfers from customers without the typical wait time of seeking confirmation from the business owners or back-office accountants.<br/>
            <br/>Giving the cashiers this power of confirmation not only improves service delivery but also increases the sales funnel for a business by catering to every customer who can pay by cash, card (and now transfer).
            Would you be interested to know how it all works?<br/>
           <br/> <strong> Watch</strong> Our Product Intros or <Link to='/contact'>Contact Us</Link>.
          </div>
          <Footer/>
        </div>
     )
}