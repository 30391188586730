import React, { useContext, useEffect, useState } from "react";
import "./Payout.scss";

import { Button, Table } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { userContext } from "../../../store/appContext";
import { formatNumber } from "../../../utils/helpers";

import * as dayjs from "dayjs";
import {
  fetchBeneficiaries,
  fetchMerchantPayouts,
  requeryTransaction,
} from "../../../services/apiSevices";
import HomeCard from "../../../components/Cards/HomeCard";
import TransferModal from "../../../components/TransferModal/TransferModal";
import WithdrawModal from "../../../components/withdrawModal/withdrawModal";
import AppAlert from "../../../components/Alert/AppAlert";
import { useFetchCodes } from "../../../hooks/useFetchCodes";
import { useShowAlert } from "../../../hooks/useShowAlert";

export default function Payouts() {
  const context = useContext(userContext);
  const {
    currentMerchant,
    payouts,
    updatePayouts,
    walletBalance,
    updateBeneficiary,
    transactionOutboundFee,
  } = context;

  const {showAlert, openAlert} = useShowAlert()

  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successCode, setSuccessCode] = useState([]);
  const [failureCodes, setFailureCodes] = useState([]);

  useFetchCodes("success", setSuccessCode);
  useFetchCodes("failure", setFailureCodes);

  let transactionCodes = [...successCode, ...failureCodes];

  const RequeryTransaction = async (transactionref) => {
    try {
      const res = await requeryTransaction(
        currentMerchant.publickey,
        transactionref
      );
      if (successCode.includes(res.transactionStatus)) {
        openAlert(true, "success", "Transaction Successful");
      } else if (failureCodes?.includes(res.transactionStatus) === 1) {
        openAlert(true, "failed", "Transaction Failed");
      } else {
        openAlert(true, "error", res.data.data.message);
      }
    } catch (error) {
      openAlert(true, "error", error?.response?.data.data);
    }
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
            maxWidth: 150,
          },
        };
      },
      render: (text, i) => (
        <span style={{ color: text === "debit" ? "red" : "green" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Narrative",
      dataIndex: "narrative",
      key: "narrative",
    },
    {
      title: "Txn Referrence",
      dataIndex: "transactionref",
      key: "transactionref",
    },
    {
      title: "Date",
      dataIndex: "created_on",
      key: "created_on",
      render: (text, i) => (
        <span>{dayjs(text).format("DD/MM/YY. hh:mm a")}</span>
      ),
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (status, i) => (
        <span
          style={{
            color:
              status === "00"
                ? "green"
                : failureCodes?.includes(status)
                ? "red"
                : " #FF9900",
          }}
        >
          {status === "00"
            ? "Successful"
            : failureCodes?.includes(status)
            ? "Failed"
            : status
            ? "Pending"
            : ""}
        </span>
      ),
    },

    {
      title: "",
      dataIndex: "status",
      key: "status",
      onCell: (record, idx) => {
        return {
          onClick: (e) => {
            if (
              record.status &&
              transactionCodes.includes(record.status) === false
            ) {
              RequeryTransaction(record.transaction_id);
            }
          },
        };
      },
      render: (status, i) => (
        <Button
          disabled={loading}
          style={{
            border:
              status && transactionCodes.includes(status) === false
                ? "none"
                : "none",
            color: "green",
            cursor: "pointer",
            backgroundColor: "rgba(71, 160, 139, 0.07)",
            fontSize: "10px",
            padding:
              transactionCodes.includes(status) === false && status
                ? "6px"
                : "none",
          }}
        >
          {status && transactionCodes.includes(status) === false
            ? "Confirm"
            : ""}
        </Button>
      ),
    },
  ];

  const data = payouts
    ? payouts.map((tnx) => {
        return {
          key: tnx.createdon,
          narrative: tnx.narrative,
          amount: `₦${formatNumber(tnx.amount)}`,
          type: tnx.type,
          created_on: tnx.createdon,
          transactionref: tnx.transactionref,
          status: tnx.status,
          transaction_id: tnx.transaction_id,
        };
      })
    : [];

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchMerchantPayouts(currentMerchant.businessid)
        .then((res) => {
          updatePayouts(res.data.data);
        })
        .catch((err) => {
          updatePayouts([]);
          console.log(err);
        });
    }
    return function cleanup() {
      mounted = false;
    };
  }, [currentMerchant.businessid]);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if(mounted){
        try {
          const response = await fetchBeneficiaries(currentMerchant.publickey);
          updateBeneficiary(response.data.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
    return () => mounted = false;
  }, [currentMerchant.publickey]);


  return (
    <div className="txns">
      <AppAlert
        show={showAlert.show}
        type={showAlert.type}
        description={showAlert.description}
      />
      <div className="wallet_card-wrapper">
        <HomeCard
          color={"#09427D"}
          heading="Wallet Balance"
          value={`₦${formatNumber(walletBalance)}`}
        />
        <div className="t_w-wrapper">
          <div
            className="bt transfer"
            onClick={() => setIsTransferModalOpen(true)}
          >
            <ArrowUpOutlined />
            <span>Transfer</span>
          </div>
          <div
            className="bt withdraw"
            onClick={() => setIsWithdrawModalOpen(true)}
          >
            <ArrowDownOutlined />
            <span>Withdraw</span>
          </div>
        </div>
      </div>
      <div className="white-bg">
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ defaultPageSize: 13 }}
        />
      </div>
      <TransferModal
        isTransferModalOpen={isTransferModalOpen}
        setIsTransferModalOpen={setIsTransferModalOpen}
        transactionOutboundFee={transactionOutboundFee}
      />
      <WithdrawModal
        isWithdrawModalOpen={isWithdrawModalOpen}
        setIsWithdrawModalOpen={setIsWithdrawModalOpen}
      />
    </div>
  );
}
