import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import "./Auth.scss";
import logo from "../../assets/logoIcon.svg";
import AppFooter from "../../components/Footer";
import AppAlert from "../../components/Alert/AppAlert";
import Registeration from "../../components/RegisterForm/Registeration";
import { useShowAlert } from "../../hooks/useShowAlert";

export default withRouter(function Signup({ history }) {
  const {showAlert, openAlert} = useShowAlert()
  const [token, settoken] = useState(sessionStorage.getItem("v_pay_token"));

  useEffect(() => {
    token && history.push("/dashboard/");
  }, []);


  return (
    <div className='auth'>
      <AppAlert
        show={showAlert.show}
        type={showAlert.type}
        description={showAlert.description}
      />
      <div className='nav'>
        <Link to='/'>
          <div className='logo-wrap'>
            <img src={logo} alt='' />
            <span className='bw-b'>
              Dart <span className='green'>Pay</span>
            </span>
            <small
              style={{
                top: "40px",
                left: "41px",
              }}
            >
              For Merchants
            </small>
          </div>
        </Link>
        <p className='already bw-m'>
          Have an account already?{" "}
          <Link to='/login'>
            {" "}
            <span>Login</span>{" "}
          </Link>
        </p>
      </div>

      <div className='form-content'>
        <p className='form-title'>
          Congrats! You’ve made a smart choice to sign up to DartPay.
        </p>
        <p className='form-subtitle bw-m'>
          We just need a little bit of information about your business.
        </p>
        <Registeration newUser={true} history={history} />
      </div>
      <AppFooter />
    </div>
  );
});
