import { Button, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import {Link} from 'react-router-dom'
import AppAlert from "../../components/Alert/AppAlert";
import { contactSupport } from "../../services/apiSevices";

import Footer from '../../components/Footer'

import logo from '../../assets/logoIcon.svg'

import "./Contact-Us.scss";
import { useShowAlert } from "../../hooks/useShowAlert"

export default function ContactUs() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [formFields, setformFields] = useState({
    subject: "",
    message: "",
  });

  const {showAlert, openAlert} = useShowAlert()

  const submitHandler = () => {
    if (!formFields.message || !formFields.subject) {
      setHasError(true);
      return;
    }
    setLoading(true);
    contactSupport()
      .then((res) => {
        console.log(res);
        openAlert(true, "success", "Message sent successfully");
      })
      .catch((err) => {
        console.log(err);
        openAlert(true, "error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkEnter = (e) => {
    if (e.key === "Enter" || e.code === "Enter") submitHandler();
  };

  return (
       <div className='contact'>
          <div className='nav'>
           <div className='logo'>
            <Link to="/">
              <img src={logo} alt='' />
              <span className='bw-b'>
                 Dart <span className='green'>Pay</span>
              </span>
            <small>For Merchants</small>
            </Link>
          </div>
          <div className='links'>
            <div className='link bw-m'>
              <Link to='/login'>
                <span style={{ color: "#000000" }}>Login</span>
              </Link>
            </div>
            <div className='link'>
              <Link to='/signup'>
                <button className='sm-btn green-btn'>Register</button>
              </Link>
            </div>
          </div>
          </div>
      <AppAlert
        show={showAlert.show}
        type={showAlert.type}
        description={showAlert.description}
      />
      <div className='white-bg'>
        <div className='page-heading'>Reach Out To Us</div>
        <div className='page-sub-heading bw-m'>
          24x7, we’ll answer your calls and provide the desired resolution to
          your concerns.
        </div>
        <p className='label'>Submit A Request</p>
        <Input
          placeholder='Subject'
          style={{ height: 50 }}
          className={`${hasError && !formFields.subject ? "has-error" : ""}`}
          onKeyDown={checkEnter}
          onChange={(e) =>
            setformFields({
              ...formFields,
              subject: e.target.value,
            })
          }
        />

        <p className='label'>Write A Message</p>
        <TextArea
          style={{ marginBottom: 30, maxHeight: '300px' , minHeight: '120px' }}
          className={`${hasError && !formFields.message ? "has-error" : ""}`}
          onKeyDown={checkEnter}
          onChange={(e) =>
            setformFields({
              ...formFields,
              message: e.target.value,
            })
          }
        />

        <Button loading={loading} onClick={submitHandler} className='dark-btn'>
          MESSAGE SUPPORT
        </Button>
      </div>
      <Footer/>
    </div>
  );
}
