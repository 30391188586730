import React from "react";
import { Modal, Row, Col, Divider } from "antd";
import {formatNumber} from '../../utils/helpers'

import './TxnDetailsModal.scss'


const TxnDetailsModal = ({ isVisible, handleModalClose , record}) => (
    <Modal
        title='Transaction Details'
        visible={isVisible}
        footer={false}
        closable={true}
        onCancel={handleModalClose}
        style={{
            color: "#09427D",
            borderRadius: 20
        }}
      >
        <div className="modal-content" style={{padding: "10px 0px 60px 0px"}}>
          <p style={{
              color: "#051C4A",
              opacity: "0.5",
              fontSize: 16,
              margin: 0,
              

          }}>
              Total amount
          </p>
          <h2 style={{
              color: "#09427D",
              fontSize: 35,
              fontWeight: "700",
          }}>
              {formatNumber(record.orderamount)}
          </h2>
          <Divider/>
          <div className="bottom-content" style={{marginTop: 20}}>
             <Row gutter={[0, 0]} style={{ height: 40}}>
                <Col span={12} style={{paddingLeft: 15}}>
                    <p style={{color : "rgba(9, 66, 125, 0.5"}}>Status</p>
                    <p style={{ backgroundColor: "#09427d0d", width: "fit-content", padding: "1px 10px", borderRadius: "11px" }}>{record.paymentstatus}</p>
                </Col>
                <Col span={12} style={{paddingLeft: 15}}>
                    <p style={{color : "rgba(9, 66, 125, 0.5"}}>Balance</p>
                    <p>{formatNumber( record.orderamount - record.balance)}</p>
                </Col>
            </Row>
            <Divider/>
            <Row gutter={[0, 0]} style={{ height: 40}}>
                 <Col span={12} style={{paddingLeft: 15}}>
                    <p style={{color : "rgba(9, 66, 125, 0.5"}}>Amount Paid</p>
                    <p>{formatNumber(record.balance)}</p>
                </Col>
                <Col span={12} style={{paddingLeft: 15}}>
                    <p style={{color : "rgba(9, 66, 125, 0.5"}}>Date</p>
                    <p>{(record.date)}</p>
                </Col>
            </Row>
            <Divider/>
            <Row gutter={[0, 0]} style={{ height: 40}}>
                 <Col span={12} style={{paddingLeft: 15}}>
                    <p style={{color : "rgba(9, 66, 125, 0.5"}}>Customer</p>
                    <p>{(record.name)}</p>
                </Col>
                <Col span={12} style={{paddingLeft: 15}}>
                    <p style={{color : "rgba(9, 66, 125, 0.5"}}>Account Number</p>
                    <p>{(record.accountnumber)}</p>
                </Col>
            </Row>
          </div>
        </div>
      </Modal>
)

export default TxnDetailsModal;