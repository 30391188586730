export const baseUrl =   process.env.REACT_APP_ENV === 'dev' ? `https://alpha.dartpay.cash/` : `https://www.dartpay.cash/`;
export const loginUrl = `${baseUrl}service/demo/v1/query/user/login`;
export const startSignupUrl = `${baseUrl}service/demo/v1/query/user/add`;
export const completeRegUrl = `${baseUrl}service/demo/v1/query/merchant/retail/add`;
export const merchantUrl = `${baseUrl}service/demo/v1/query/merchant/`;
export const cashpointUrl = `${baseUrl}service/demo/v1/query/merchant/cashpoint/add`;
export const cashpointCustomersUrl = `${baseUrl}service/demo/v1/query/cashpoint/`;
export const contactSupportUrl = `${baseUrl}service/demo/v1/query/messaging/support/send`;
export const fetchMerchantUrl = `${baseUrl}service/demo/v1/query/merchant`;
export const createTnxUrl = `${baseUrl}service/demo/v1/query/cashpoint/retail/order/add`;
export const changePasswordUrl = `${baseUrl}service/demo/v1/query/user/changepassword`;
export const resetPwrdUrl = `${baseUrl}service/demo/v1/query/user/forgotpassword`;
export const currentUserUrl = `${baseUrl}service/demo/v1/query/merchant/whoami`;
export const bankNameUrl = `${baseUrl}service/demo/v1/query/merchant/bank/name/show`;
export const addBankDetailsUrl = `${baseUrl}service/demo/v1/query/merchant/bank/add`;
export const banksListUrl = `${baseUrl}service/demo/v1/query/merchant/bank/list/show`;
export const cashpointDeactivateUrl = `${baseUrl}service/demo/v1/query/merchant/cashpoint/deactivate`;
// export const merchantCashpoints = `${baseUrl}service/demo/v1/query/merchant/cashpoint/deactivate`;