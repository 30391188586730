import React from "react";
import {Link} from 'react-router-dom'
import "./footer.scss";
export default function AppFooter() {
  return (
    <footer>
      <p>
        <Link to="/t&c">
          <span>Terms</span>
        </Link>
        <Link to="/t&c">
          <span>Privacy Policy</span>
        </Link>
       <Link to='/about'>
          <span>About Us</span>
       </Link>
        <Link to="/contact">
          <span>Contact Us</span>
        </Link>
      </p>
      <p>
        <span>© 2020. DartPay. All rights reserved.</span>
      </p>
    </footer>
  );
}
