import React, {useContext, useState} from "react";
import {Link} from 'react-router-dom'

import { Button, Checkbox, Col, Input, Row, Select } from "antd";
import Password from "antd/lib/input/Password";
import AppAlert from "../Alert/AppAlert";
import { completeSignup, startSignup } from "../../services/apiSevices";
import { userContext } from "../../store/appContext";

import {useShowAlert} from '../../hooks/useShowAlert'

import "../../views/Auth/Auth.scss";

export default function Registeration({ finishReg, newUser, history }) {
  const context = useContext(userContext);
  const { user, options } = context;
  const {showAlert, openAlert} = useShowAlert()
  const [startSignupData, setstartSignupData] = useState({
    email: "",
    password: "",
  });
  const [completeSignupData, setcompleteSignupData] = useState({
    businessname: "",
    businessphone: "",
    industry: "",
    contactfirstname: "",
    contactlastname: "",
    referralcode: "",
    address: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [terms, setTerms] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [confirmPassword , setConfirmPassword] = useState("")
  const [isGoodPasword , setIsGoodPassword] = useState(false)

  const onSearchChange = (e) => {
    setSearchField(e);
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchField.toLowerCase())
  );

  const doChecks = (password) => {
    let hasNumber = /\d/g.test(password) ? 1 : 0;
    let hasUppercase = /[A-Z]/g.test(password) ? 1 : 0;
    let has8 = password.length >= 8 ? 1 : 0;
    let hasLength = password.length ? 1 : 0;
    let hasSpecialCharacter = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?~]/.test(
      password
    )
      ? 1
      : 0;

      if(hasUppercase && hasNumber && has8 && hasLength && hasSpecialCharacter){
       setIsGoodPassword(true)
     }else{
       setIsGoodPassword(false)
     }

    let total =
      hasNumber + has8 + hasSpecialCharacter + hasUppercase + hasLength;
    setPasswordStrength(total);
  };

  const clearSignUpForm = () => {
    setcompleteSignupData({
      businessname: "",
      businessphone: "",
      industry: "",
      contactfirstname: "",
      contactlastname: "",
      referralcode: "",
      address: "",
    });
    setstartSignupData({
      email: "",
      password: "",
   })
   setConfirmPassword("")
  }

  const signupHandler1 = () => {
    const {referralcode, ...other} = completeSignupData
    let fullPayload = {
      ...startSignupData,
      ...other,
    };

    // delete fullPayload.referralcode;
    let valuesArray = Object.values(fullPayload)

    if (newUser && valuesArray.includes("")) {
      setErrors(true);
      return;
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!terms) {
      openAlert(
        true,
        "error",
        `Kindly accept the terms and conditions to proceed
         `
      );
      return;
    }

    if(!re.test(String(startSignupData.email).toLowerCase()) && startSignupData.email !== ""){
      openAlert(
        true,
        "error",
        `Invalid email
         `
      );
      return;
    }
    

    setErrors(false);
    setLoading(true);
    let data = {
      ...startSignupData,
    };

    if (newUser) {
      if(isGoodPasword === false){
        setLoading(false)
        return;
      }
      if(startSignupData.password !== confirmPassword){ openAlert(true, "error", "Passwords do not match"); setLoading(false); return} 
        startSignup(data)
          .then((res) => {
            let id = res.data.id;
            signupHandler2(id);
          })
          .catch((err) => {
            setLoading(false);
            openAlert(true, "error", err.response?.data?.message);
          });
    } else {
      signupHandler2(user.user);
    }
  };

  const signupHandler2 = (user) => {
    let data = {
      email: startSignupData.email,
      ...completeSignupData,
      user,
    };

    const {referralcode, ...other} = completeSignupData
     
    let dataToCheck = {
      email: startSignupData.email,
      ...other,
      user,
    }

    setLoading(true);

    let valuesArray = Object.values(dataToCheck)

    if (valuesArray.includes("") ){
      setErrors(true);
      setLoading(false);
      return;
    }
    setErrors(false);
    
    completeSignup(data)
      .then((res) => {
        openAlert(true, "success", "Account created successfully");
        clearSignUpForm()
        setTimeout(() => {
          newUser ? history.push("/login") : finishReg();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        openAlert(true, "error", err.response.data.message);
      })
  };

 
  const checkEnter = (e) => {
    if (e.key === "Enter" || e.code === "Enter") signupHandler1();
  };

  return (
    <div className='form-content'>
      <AppAlert
        show={showAlert.show}
        type={showAlert.type}
        description={showAlert.description}
      />
      <Row gutter={20}>
        <AppAlert
          show={showAlert.show}
          type={showAlert.type}
          description={showAlert.description}
        />
            <Col sm={24} md={12}>
              <p className='label bw-m rq'>Contact Person</p>
              <Input
                style={{ height: 55 }}
                bordered={false}
                size='large'
                placeholder='Firstname'
                value={completeSignupData.contactfirstname}
                onKeyDown={checkEnter}
                onChange={(e) => {
                  const firstname = e.target.value.replace(/[^a-zA-Z\s]/, '');
                  setcompleteSignupData({
                    ...completeSignupData,
                    contactfirstname: firstname,
                  });
                }}
                className={
                  errors && !completeSignupData.contactfirstname && "has-error"
                }
              />
            </Col>
            <Col sm={24} md={12}>
              <p className='label bw-m hide rq'>B</p>
              <Input
                style={{ height: 55 }}
                bordered={false}
                size='large'
                placeholder='Lastname'
                value={completeSignupData.contactlastname}
                onKeyDown={checkEnter}
                onChange={(e) => {
                  const lastname = e.target.value.replace(/[^a-zA-Z\s]/, '');
                  setcompleteSignupData({
                    ...completeSignupData,
                    contactlastname: lastname,
                  });
                }}
                className={
                  errors && !completeSignupData.contactlastname && "has-error"
                }
              />
            </Col>
        <Col sm={24} md={12}>
          <p className='label bw-m  rq hide' style={{marginTop: 0}}>Business Contact</p>
          <Input
            placeholder='Email Address'
            style={{ height: 55 }}
            bordered={false}
            size='large'
            value={startSignupData.email}
            onKeyDown={checkEnter}
            onChange={(e) => {
              setstartSignupData({
                ...startSignupData,
                email: e.target.value.trim(),
              });
            }}
            className={errors && !startSignupData.email && "has-error"}
          />
        </Col>
        <Col sm={24} md={12}>
          <p className='label bw-m hide rq' style={{marginTop: 0}}>m</p>
          <Input
            type="number"
            placeholder='Phone'
            style={{ height: 55 }}
            bordered={false}
            size='large'
            value={completeSignupData.businessphone}
            onKeyDown={checkEnter}
            onChange={(e) => {
              setcompleteSignupData({
                ...completeSignupData,
                businessphone: e.target.value,
              });
            }}
            className={
              errors && !completeSignupData.businessphone && "has-error"
            }
          />
        </Col>


        <Col sm={24} md={24}>
          <p className='label bw-m  rq'>Business Contact</p>
          <Input
            placeholder='Business Name'
            style={{ height: 55 }}
            bordered={false}
            size='large'
            value={completeSignupData.businessname}
            onKeyDown={checkEnter}
            onChange={(e) => {
              setcompleteSignupData({
                ...completeSignupData,
                businessname: e.target.value,
              });
            }}
            className={
              errors && !completeSignupData.businessname && "has-error"
            }
          />
        </Col>

        <Col sm={24} md={12}>
          <p className='label bw-m rq'>Business Industry</p>
          <Select
            showSearch
            placeholder= "Business Industry"
            onSearch={onSearchChange}
            style={{ height: 55, width: "100%" }}
            bordered={false}
            size='large'
            value={completeSignupData.industry}
            onKeyDown={checkEnter}
            onChange={(e) => {
              setcompleteSignupData({
                ...completeSignupData,
                industry: e,
              });
            }}
            className={errors && !completeSignupData.industry && "has-error"}
          >
            {filteredOptions.map((option, idx) => (
              <Select.Option key={idx} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Col>

        <Col sm={24} md={12}>
          <p className='label bw-m hide'>Referral Code (Optional)</p>
          <Input
            placeholder='Referral Code (Optional)'
            style={{ height: 55 }}
            bordered={false}
            size='large'
            value={completeSignupData.referralcode}
            autoComplete="off"
            onKeyDown={checkEnter}
            onChange={(e) => {
              setcompleteSignupData({
                ...completeSignupData,
                referralcode: e.target.value,
              });
            }}
          />
        </Col>

        <Col sm={24} md={24}>
          <p className='label bw-m rq hide' style={{marginTop: 0}}>Address</p>
          <Input
            placeholder='Address'
            style={{ height: 55 }}
            bordered={false}
            size='large'
            value={completeSignupData.address}
            autoComplete="autocomplete_off_hack_xfr4!k"
            onKeyDown={checkEnter}
            onChange={(e) => {
              setcompleteSignupData({
                ...completeSignupData,
                address: e.target.value,
              });
            }}
            className={errors && !completeSignupData.address && "has-error"}
          />
        </Col>

        {newUser && (
          <>
          <Col sm={24} md={24}>
            <p className='label bw-m  rq'>Password</p>
            <Password
              style={{ height: 55 }}
              bordered={false}
              placeholder="Enter Password"
              size='large'
              onKeyDown={checkEnter}
              value={startSignupData.password}
              autoComplete="off"
              onChange={(e) => {
                doChecks(e.target.value);

                setstartSignupData({
                  ...startSignupData,
                  password: e.target.value,
                });
              }}
              className={errors && !startSignupData.password && "has-error"}
            />
            <div className='bars'>
              {new Array(5).fill("o").map((n, i) => {
                return (
                  <div
                    key={i}
                    className={`bar ${
                      i + 1 <= passwordStrength ? "filled" : ""
                       }`}
                    ></div>
                    );
                   })}
              </div>
            { 
             !isGoodPasword && startSignupData.password.length >= 1 && <p className='caveat' style={{ color: "red"}}>
                Password must have at least one number<br/>
                Password must have at least one special character<br/>
                Password must have an Uppercase character<br/>
                Password Length must be up to 8<br/>
             </p>
           }
          </Col>

           <Col sm={24} md={24}>
            <p className='label bw-m  rq hide' style={{marginTop: 0}}>Confirm Password</p>
            <Password
              style={{ height: 55 }}
              bordered={false}
              size='large'
              placeholder="Confirm Password"
              onKeyDown={checkEnter}
              value={confirmPassword}
              autoComplete="off"
              onChange={(e) => {
               setConfirmPassword(e.target.value)
              }}
              className={errors && !confirmPassword && "has-error"}
            />
            </Col>
            
            </>

        )}

        <Col xs={24} md={24}>
         <Checkbox
            style={{ marginTop: 10 }}
            onChange={(e) => setTerms(e.target.checked)}
            checked={terms}
          />
          <span style={{ marginLeft: 10}}>
            By clicking “GET STARTED” below you agree that you have read and
            accepted the <Link to="/t&c" style={{ textDecoration: "none" , color: "black"}}>Terms of DartPay.</Link>
          </span>
          <Button
            loading={loading}
            className='dark-btn'
            style={{
              width: "100%",
              height: 50,
              borderRadius: 5,
              marginTop: 20,
            }}
            onClick={signupHandler1}
          >
            GET STARTED
          </Button>
        </Col>
      </Row>
    </div>
  );
}
