import { useEffect, useContext } from "react";
import { fetchCodes } from "../services/apiSevices";
import { userContext } from "../store/appContext";

/**
 *
 * @param {string} type either success or failure
 * @param {React setState function} setCode
 */

export const useFetchCodes = (type, setCode) => {
  const context = useContext(userContext);
  const { currentMerchant } = context;
  useEffect(() => {
    let mounted = true
    const fetchdata = async () => {
      if(mounted) {
        try {
          const res = await fetchCodes(currentMerchant.publickey, type);
          setCode(res.data.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchdata();
    return () => mounted = false;
  }, []);
};
