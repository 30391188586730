import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Col, Row } from "antd";
import { HashRouter, Route, Switch, useRouteMatch } from "react-router-dom";
import DashboardHome from "./DashboardViews/DashboardHome";
import CashPoints from "./CashPoints/CashPoints";
import Settings from "./Settings/Settings";
import { fetchBanks, fetchMerchant } from "../../services/apiSevices";
import { userContext } from "../../store/appContext";
import Payouts from "./Payout/Payout";

export default function Dashboard() {
  const context = useContext(userContext);
  const {
    updateBanks,
    currentMerchant,
    updateShowCaution,
    showCaution,
    updateCurrentMerchant,
    currentPage,
  } = context;
  let match = useRouteMatch();

  useEffect(() => {
    fetchMerchant(currentMerchant.businessid).then((res) => {
      updateCurrentMerchant({
        ...currentMerchant,
        bank: res.data.bank,
        businessemail: res.data.businessemail,
      });
      if (res.data.bank) {
        updateShowCaution({ ...showCaution, showCaution: false });
      } else {
        updateShowCaution({ ...showCaution, showCaution: true });
      }
    });
  }, [updateCurrentMerchant]);

  useEffect(() => {
    fetchBanks().then((res) => {
      updateBanks(res.data.banks);
    });
  }, []);

  const handleClearCaution = () => {
    updateShowCaution({ ...showCaution, showCaution: false });
  };

  return (
    <div className="dashboard">
      <Sidebar width={250} />
      <div className="dash-body">
        <div className="dashboard-heading">
          <div
            style={{
              position: "absolute",
              backgroundColor: "red",
              top: "0px",
              justifyContent: "center",
              left: "0px",
              right: "0px",
              color: "white",
              padding: "20px 8px",
              display: "flex",
              alignItems: "center",
              zIndex: 999,
              visibility: `${showCaution.showCaution ? "visible" : "hidden"}`,
            }}
          >
            <p
              className="caution-msg"
              style={{
                fontSize: "15px",
                fontWeight: 700,
                marginBottom: 0,
              }}
            >
              Please click on the Settings menu and setup your bank details to
              recieve your customer Payments
            </p>
            <span
              onClick={handleClearCaution}
              style={{
                fontSize: "25px",
                cursor: "pointer",
                position: "absolute",
                right: 20,
              }}
            >
              &#10005;
            </span>
          </div>
          <Row gutter={20}>
            <Col xs={18}>
              <p className="page-title bw-b">{currentPage}</p>
            </Col>
            <Col xs={6} style={{ marginTop: 10 }}></Col>
          </Row>
        </div>

        <div className="dash-contents">
          <HashRouter>
            <Switch>
              <Route exact path={`${match.path}`}>
                <DashboardHome />
              </Route>
              <Route exact path={`${match.path}/cash-points`}>
                <CashPoints />
              </Route>
              <Route exact path={`${match.path}/wallet`}>
                <Payouts />
              </Route>
              <Route exact path={`${match.path}/settings`}>
                <Settings />
              </Route>
            </Switch>
          </HashRouter>
        </div>
      </div>
    </div>
  );
}
