import React from "react";
import { Line } from "react-chartjs-2";

export default function LineChart({ state }) {
  return (
    <Line
      data={state}
      height={120}
      width={100}
      options={{
        title: {
          display: false,
        },
        lineTension: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#939393",
                fontSize: 12,
              },
              gridLines: {
                borderDash: [3, 6],
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              barThickness: 15,
              gridLines: {
                display: false,
                color: "#9393938f",
              },
              ticks: {
                fontColor: "#939393",
                fontSize: 12,
              },
            },
          ],
        },
      }}
    />
  );
}
