import React, { useContext, useEffect, useState } from "react";
import "./CashPoints.scss";

import {
  Table,
  Dropdown,
  Menu,
  Row,
  Col,
  Modal,
  Input,
  Button,
  Empty,
} from "antd";
import { Line } from "react-chartjs-2";
import {
  CaretUpFilled,
  PlusOutlined,
  CaretDownFilled,
  // CloseOutlined,
} from "@ant-design/icons";
import { userContext } from "../../../store/appContext";

import AppAlert from '../../../components/Alert/AppAlert';
import TxnDetailsModal from '../../../components/TxnDetailsModal/TxnDetailsModal'

import * as dayjs from "dayjs";
import {
  addCashpoint,
  fetchMerchantCashpoints,
  fetchMerchantCustomersCashpoints,
  deactivateCashpoint,
} from "../../../services/apiSevices";
import { formatNumber, lastPointCustomer } from "../../../utils/helpers";
import { useShowAlert } from "../../../hooks/useShowAlert";


export default function CashPoints() {
  const context = useContext(userContext);
  const {updateCashpoints, cashpoints, currentMerchant } = context;
  const [cashpoint, setCashpoint] = useState(cashpoints[0] || {});
  const [cashpointID, setCashpointID] = useState(cashpoint._id);
  const [pointCustomers, setPointCustomers] = useState([]);
  const [loadingData, setloadingData] = useState(false);
  const [currentRecord, setIsCurrentRecord] = useState(null)
  const [tnxTotal, setTnxTotal] = useState(0);

  const [lineData, setLineData] = useState({ labels: [], data: [] });
  const chartData = {
    labels: lineData.labels,
    datasets: [
      {
        backgroundColor: "#FFB4E4",
        fill: false,
        borderColor: "#790E53",
        lineTension: 0,
        borderWidth: 0,
        pointRadius: 0,
        data: lineData.data,
      },
    ],
  };
  const columns = [
    {
      title: "Order Amount",
      dataIndex: "orderamount",
      key: "orderamount",
      render: (text, i) => <span>₦{formatNumber(text)}</span>,
    },
    {
      title: "Paid Amount",
      dataIndex: "balance",
      key: "balance",
      render: (text, i) => <span>₦{formatNumber(text)}</span>,
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Destination",
      dataIndex: "accountnumber",
      key: "accountnumber",

      render: (text, i) => <span>{text || "Not Available"}</span>,
    },
    {
      title: "Date",
      dataIndex: "created_on",
      key: "created_on",
      render: (text, i) => (
        <span>{dayjs(text).format("DD/MM/YY. hh:mm a")}</span>
      ),
    },
      {
      title: "Status",
      dataIndex: "paymentstatus",
      key: "paymentstatus",
      render: (text, i) => (
        <span
          style={{
            color: (text === "paid" ? "#47A08B" : ( text === "pending" ? "red"  :"#01B2E6"))
          }}
        >
          {text}
        </span>
      ),
    },
    // {
    //   title: "Txn Referrence",
    //   dataIndex: "transactionref",
    //   key: "transactionref",
    // },
 
  ];

  useEffect(() => {
    // get total paid transactions
    let mounted = true;
    if(mounted){
    let total = pointCustomers
      .filter((t) => t.paymentstatus !== "pending")
      .reduce((cumm, curr) => (cumm += curr.accountbalance), 0);
    setTnxTotal(total);

    if (pointCustomers) {
      let customers_ = pointCustomers
        .map((c) => {
          return {
            ...c,
            date: dayjs(c.createdon).format("MMM"),
          };
        })
        .reverse()
        .reduce((cumm, curr) => {
          cumm[curr.date]
            ? (cumm[curr.date] = cumm[curr.date] + 1)
            : (cumm[curr.date] = 1);
          return cumm;
        }, {});

      let lineLabelsArray =
        Object.keys(customers_).length === 1
          ? [
              dayjs(pointCustomers[0].createdon)
                .subtract(2, "month")
                .format("MMM"),
              dayjs(pointCustomers[0].createdon)
                .subtract(1, "month")
                .format("MMM"),
              ...Object.keys(customers_),
            ]
          : Object.keys(customers_);

      let lineDataArray =
        Object.keys(customers_).length === 1
          ? [0, 0, ...Object.values(customers_)]
          : Object.values(customers_);

      setLineData({
        labels: lineLabelsArray,
        data: lineDataArray,
      });
    }}
    return function cleanup(){
      mounted = false
    }
  }, [pointCustomers]);

  

  const data = pointCustomers
    ? pointCustomers.map((customer, i) => {
        return {
          key: i,
            orderamount: customer.orderamount,
            name: customer.name,
            accountnumber: customer.accountnumber,
            transactionref: customer.transactionref,
            paymentstatus: customer.paymentstatus,
            balance: customer.accountbalance,
            date: dayjs(customer.createdon).format("DD/MM/YY. hh:mm a"),
        };
      })
    : [];

  useEffect(() => {
    cashpoints.length && getPointCustomers(cashpointID);
  }, [cashpointID]);

  const [showAdd, setShowAdd] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const {showAlert, openAlert} = useShowAlert()
  const [newCashpointData, setNewCashpointData] = useState({
    email: "",
    phone: "",
    contactfirstname: "",
    contactlastname: "",
    merchant: currentMerchant.businessid,
  });

  const createCashpointHandler = () => {
    let values = Object.values(newCashpointData);

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

     if (values.includes("")) {
      setErrors(true);
      return;
    }
      if(!re.test(String(newCashpointData.email).toLowerCase())){
      openAlert(
        true,
        "error",
        `Invalid email
         `
      );
      return;
    }
    setLoading(true);
    addCashpoint(newCashpointData)
      .then((res) => {
        fetchMerchantCashpoints(currentMerchant.businessid)
        .then((res) => {
          updateCashpoints(res.data);
           setCashpoint((res.data)[0])
            setCashpointID((res.data)[0]._id)
        })
        .catch((err) => {
          console.log(err);
        });
        openAlert(true , "success" ,  "Cashpoint created successfully. Account password has been sent to cashpoint's email and phone.")
        setNewCashpointData({
          email: "",
          phone: "",
          contactfirstname: "",
          contactlastname: "",
          merchant: currentMerchant.businessid,
        });
        setShowAdd(false);
        setLoading(false)
      })
      .catch((err) => { 
        setLoading(false);
        openAlert(true, "error",  err.response?.data.message);
        console.log(err);
      })
  };

  const getPointCustomers = (id) => {
    let data = {
      period: "year",
    };
    setloadingData(true);
    fetchMerchantCustomersCashpoints(id, data)
      .then((res) => {
        setPointCustomers(res.data);
        setloadingData(false);
      })
      .catch((err) => {
        console.log(err); 
        setPointCustomers([]);
        setloadingData(false);
      })
  };

  const cashpointDeactivate = () => {
    let data = {
      merchant : currentMerchant.businessid,
      cashpoint: cashpointID,
    }
    setLoading(true)
    deactivateCashpoint(data)
    .then((res) => {
      fetchMerchantCashpoints(currentMerchant.businessid)
          .then((res) => {
            updateCashpoints(res.data);
            setCashpoint((res.data)[0] || {})
            setCashpointID((res.data)[0]?._id)
          })
          .catch((err) => {
            console.log(err);
          });
      setLoading(false)
      openAlert(true, "success", "CashPoint Deactivated Successfully")
      setDeactivate(false)
    })
    .catch((err) => {
      console.log(err)
      setLoading(false)
    })
  }


   const handleModalClose = () => {
    setIsCurrentRecord(null)
  }

  const handleModalOpen = (record) => {
    setIsCurrentRecord(record)
  }


  return (
    <Row className='cash-points' gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
      <AppAlert
        show={showAlert.show}
        type={showAlert.type}
        description={showAlert.description}
      />

      <Col xs={24} md={8} className='top-section left-col'>
        <div className='points'>
          {cashpoints.length ? (
            <div className='vpay-card'>
              <div className='bg'></div>
              <div
                className='overlay'
                style={{ backgroundColor: "#09427D" }}
              ></div>
              <div className='card-texts'>
                <div style={{ fontFamily: "BW-modelica-medium" , fontWeight: "bold", color: "white" }}>
                   {cashpoint.contactfirstname} {cashpoint.contactlastname}
                </div>
                <div className='data'>
                  <div className='big'>
                    <p className='btm'>₦{formatNumber(tnxTotal)}</p>
                    <p className='top'>
                      Last transaction 
                      <span style={{fontWeight: "bold", fontSize: 15}}> ₦{formatNumber(
                        pointCustomers.length && lastPointCustomer(pointCustomers)?.accountbalance
                          ? lastPointCustomer(pointCustomers)
                              ?.accountbalance
                          : 0
                      )}
                      </span>
                    </p>
                  </div>
  
                   <Dropdown 
                     overlay={
                    <Menu>
                      {cashpoints.map((point) => (
                        <Menu.Item
                          key={point._id}
                          onClick={(e) => {
                          setCashpointID(point._id);
                          setCashpoint(cashpoints.find((c) => c._id === point._id));
                         }}
                        >
                         {point.contactfirstname} {point.contactlastname}
                        </Menu.Item>
                      ))}
                   </Menu>
                  }
                  >
                        <div className='gradient' style={{
                           marginTop: 18,
                           backgroundColor: "#47A08B",
                           display: "flex",
                           padding: "11px 17px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: 5,
                          cursor: "pointer"
                        }}>
                          <span className="cash" style={{
                              fontSize: 16,
                              color: '#fff',
                              fontWeight: 600,
                          }}>Cash Points</span>
                          <span className='sm'>
                            <div
                              className='carets'
                              style={{
                                float: "right",
                                display: "flex",
                                flexDirection: "column",
                                marginTop: -5,
                              }}
                            >
                              <CaretUpFilled style={{ fontSize: 10, color: "#fff" }} />
                              <CaretDownFilled style={{ fontSize: 10, color: "#fff" }} />
                            </div>
                          </span>
                        </div> 
                  </Dropdown>  
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className='add' onClick={() => setShowAdd(true)}>
          <PlusOutlined style={{ fontSize: 20, color: "#1a4279" }} />
          <span style={{ color: "#1a4279" , fontSize: 13, fontWeight: "bold" , marginLeft: 10}}>Add CashPoint</span>
        </div>

         <div className='white-bg pull-up ' style={{
           marginTop:20,
           width: 330,
           borderRadius: 12,

         }}>
            <div className='chart-heading'>
              <div>
                <p className='rev bw-b'>Customer Growth</p>
              </div>
              {/* <div className='calendar'>
                <CalendarOutlined />
              </div> */}
            </div>
            {pointCustomers.length ? (
              <Line
                data={chartData}
                height={80}
                width={100}
                options={{
                  title: {
                    display: false,
                  },
                  lineTension: false,
                  legend: {
                    display: false,
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          fontColor: "#939393",
                          fontSize: 12,
                        },
                        gridLines: {
                          borderDash: [3, 6],
                          drawBorder: false,
                        },
                      },
                    ],
                    xAxes: [
                      {
                        barThickness: 15,
                        gridLines: {
                          display: false,
                          color: "#9393938f",
                        },
                        ticks: {
                          fontColor: "#939393",
                          fontSize: 12,
                        },
                      },
                    ],
                  },
                }}
              />
            ) : (
              <>
                <Empty />
              </>
            )}
          </div>
      </Col>
      <Col xs={24} md={16}>
      <Row gutter={20}>
        <Col xs={32} md={24}>
          <div className='white-bg'>
            <Table
              columns={columns}
              dataSource={data}
              onRow={(record, recordIndex) => ({
               onClick: event => { 
                 setIsCurrentRecord(record)} 
            })}
              pagination={{ defaultPageSize: 10 }}
            />
          </div>
          <div 
          className="deactivate"
           style={{fontSize: 18,
            display: "flex" , 
            justifyContent: "flex-end", 
            marginTop: 10, 
            fontFamily: "BW-modelica-medium", 
            opacity: 0.5}}>
             <Button 
             disabled={ cashpoints?.length < 1 ?  true : false}
             onClick={() => setDeactivate(true)} 
             style={{ border: "none", background: "none" }}
             >
                Deactivate Cashier
             </Button>
          </div>
        </Col>
      </Row> 
      </Col>

      <Modal
        title='Add Cash Point'
        visible={showAdd}
        footer={false}
        closable={true}
        onCancel={() => setShowAdd(false)}
      >
        <div style={{ padding: 15 }}>
          <Row gutter={20}>
            <Col span={12}>
              <p className='label bw-m rq' style={{ marginBottom: 3, position: "relative" }}>
                First Name<span style={{color: "red", fontSize:30, position: "absolute", top: "-5px"}}>*</span>
              </p>
              <Input
                bordered={false}
                style={{ width: "100%", height: 50, marginBottom: 20 }}
                value={newCashpointData.contactfirstname}
                className = {
                  errors && !newCashpointData.contactfirstname && "has-error"
                }
                onChange={(e) => {
                  const firstname = e.target.value.replace(/[^a-zA-Z\s]/, '');
                  setNewCashpointData({
                    ...newCashpointData,
                    contactfirstname: firstname,
                })
                }}
              />
            </Col>
            <Col span={12}>
              <p className='label bw-m rq' style={{ marginBottom: 3 , position: "relative"}}>
                Last Name<span style={{color: "red", fontSize:30, position: "absolute", top: "-5px"}}>*</span>
              </p>
              <Input
                bordered={false}
                style={{ width: "100%", height: 50, marginBottom: 20 }}
                value={newCashpointData.contactlastname}
                className={
                  errors && !newCashpointData.contactlastname && "has-error"
                }
                onChange={(e) => {
                  const lastname = e.target.value.replace(/[^a-zA-Z\s]/, '');
                  setNewCashpointData({
                    ...newCashpointData,
                    contactlastname: lastname,
                  })
                }}
              />
            </Col>
          </Row>
          <p className='label bw-m rq' style={{ marginBottom: 3 , position: "relative"}}>
            Email Address<span style={{color: "red", fontSize:30, position: "absolute", top: "-5px"}}>*</span>
          </p>
          <Input
            bordered={false}
            style={{ width: "100%", height: 50, marginBottom: 20 }}
            value={newCashpointData.email}
            className={errors && !newCashpointData.email && "has-error"}
            onChange={(e) =>
              setNewCashpointData({
                ...newCashpointData,
                email: e.target.value.trim(),
              })
            }
          />
          <p className='label bw-m rq' style={{ marginBottom: 3, position: "relative" }}>
            Phone<span style={{color: "red", fontSize:30, position: "absolute", top: "-5px"}}>*</span>
          </p>
          <Input
            type='number'
            bordered={false}
            style={{ width: "100%", height: 50, marginBottom: 20 }}
            value={newCashpointData.phone}
            className={errors && !newCashpointData.phone && "has-error"}
            onChange={(e) =>
              setNewCashpointData({
                ...newCashpointData,
                phone: e.target.value,
              })
            }
          />

          <div
            className='buttons'
            style={{ textAlign: "right", marginTop: 30 }}
          >
            <button
              className='white-btn_'
              style={{
                padding: "0 20px",
                borderRadius: 5,
                height: 50,
                marginRight: 20,
              }}
              onClick={() => setShowAdd(false)}
            >
              CANCEL
            </button>
            <Button
              className='dark-btn'
              style={{ padding: "0 20px", borderRadius: 5, height: 50 }}
              onClick={createCashpointHandler}
              loading={loading}
            >
              ADD CASHPOINT
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        title="Deactivate cashier's profile"
        visible={deactivate}
        footer={false}
        closable={true}
        onCancel={() => setDeactivate(false)}
        style={{ textAlign: "center" , borderRadius: 12 }}
      >
         <p>
           Cashiers profile will be deactivated if you click the <br/>deactivate button. Not sure? cancel deactivation
         </p>
         <div className="buttons" style={{ marginTop: 20}}>
           <Button 
           onClick={() => setDeactivate(false)}
           style={{ 
            padding: "0 20px", 
            borderRadius: "7px",
            height: 35 , 
            color: "white" , 
            backgroundColor: "#B9B9B9" ,
            border: "0.5px solid #7070704D" , 
            outline: "none",
            marginRight: 15,
            fontSize:13,
            fontFamily: "BW-modelica-medium"
            }}>
               CANCEL
           </Button>
           <Button 
           onClick={cashpointDeactivate}
           loading={loading}
           style={{ 
            padding: "0 20px", 
            borderRadius: "7px",
            height: 35 , 
            color: "white" , 
            backgroundColor: "#09427d" ,
            border: "0.5px solid #7070704D" , 
            outline: "none" ,
            fontSize:13,
            fontFamily: "BW-modelica-medium"
            }}>
               DEACTIVATE
           </Button>
         </div>
      </Modal>
       {
      currentRecord && <TxnDetailsModal
      record={currentRecord}
      handleModalClose={handleModalClose}
      isVisible={!!currentRecord}
      />
      }
    </Row>
  );
}
