import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Input, Modal, Row, Select, Tabs, Switch } from "antd";

import "./Settings.scss";

import {
  addBankDetails,
  changePassword,
  fetchMerchant,
  fetchMerchantKey,
  toggleFundDestination,
  verifyBankDetails,
} from "../../../services/apiSevices";
import { userContext } from "../../../store/appContext";
import { CopyToClipboard } from "react-copy-to-clipboard";

import AppAlert from "../../../components/Alert/AppAlert";
import Support from "../Support/Support";
import { useShowAlert } from "../../../hooks/useShowAlert";

const { TabPane } = Tabs;

export default function Settings() {
  const context = useContext(userContext);
  const {
    user,
    currentMerchant,
    updateCurrentMerchant,
    options,
    banks,
    updateShowCaution,
    showCaution,
  } = context;
  const {showAlert, openAlert} = useShowAlert()
  const [defaultKey, setDefaultKey] = useState("1");
  const [showAdd, setShowAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchingKey, setFetchingKey] = useState(false);
  const [updateForm, setUpdateForm] = useState({});
  const [privatekey, setPrivatekey] = useState("");
  const [isGoodPasword, setIsGoodPassword] = useState(false);

  const [newPasswords, setnewPasswords] = useState({
    newpwrd: "",
    newPwrd2: "",
  });
  const [bankData, setBankData] = useState({
    accountnumber: "",
    bankcode: "",
  });
  const [editData, updateEditData] = useState({});
  const [bankDataVerified, setbankDataVerified] = useState(false);
  const [bankName, setbankName] = useState("");
  const [checked, setChecked] = useState(currentMerchant.keepfundsinwallet);
  const TEXT = "Keep Funds In Wallet";
  const [searchField, setSearchField] = useState("");

  const onSearchChange = (e) => {
    setSearchField(e);
  };

  const filteredBanks = banks.filter((bank) =>
    bank.name.toLowerCase().includes(searchField.toLowerCase())
  );

  /**
   * get bank name corresponding to the bank code provided
   * @param {number} code
   */

  const getBankName = (code) => {
    let foundBank = banks.find((bank) => bank.code === code);
    return foundBank ? foundBank.name : "";
  };

  const onSwitchChange = async (e) => {
    try {
      await toggleFundDestination(currentMerchant.businessid);
      setChecked((checked) => !checked);
      const res = await fetchMerchant(currentMerchant.businessid);
      updateCurrentMerchant({ ...currentMerchant, ...res.data });
    } catch (error) {
      openAlert(true, "error", error.response?.data?.message);
    }
  };

  const updateBankDetails = () => {
    setLoading(true);
    let data = {
      ...bankData,
      bankname: bankName,
      merchant: currentMerchant.businessid,
    };
    data.bankcode = data.bankcode.split("|")[0];

    addBankDetails(data)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        updateShowCaution({ ...showCaution, showCaution: false });
        fetchMerchantData();
        openAlert(true, "success", "Bank details updated");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let cleanup = true;
    if(cleanup){
      if (currentMerchant.bank) {
        setBankData({
          accountnumber: currentMerchant.bank.number,
          bankcode: currentMerchant.bank.code,
        });
      }
    }
    return () => cleanup = false
  }, [currentMerchant.bank]);

  useEffect(() => {
    let cleanup = true;
    if(cleanup){
      fetchMerchant(currentMerchant.businessid)
        .then((res) => {
          updateCurrentMerchant({ ...currentMerchant, ...res.data });
          setUpdateForm({
            ...updateForm,
            businessphone: currentMerchant.businessphone,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => cleanup = false
  }, []);

  const verifyData = () => {
    if (bankData.accountnumber && bankData.bankcode) {
      bankData.bankcode = bankData.bankcode.split("|")[0];
      setLoading(true);
      verifyBankDetails(bankData)
        .then((res) => {
          if (res.data) {
            setbankName(res.data.accountname);
            setbankDataVerified(true);
            setLoading(false);
            openAlert(true, "success", "Bank details verified");
          }
        })
        .catch((err) => {
          openAlert(true, "error", (err.response || {}).data.message);
          setLoading(false);
          // console.log(err.response, err);
        });
    }
  };

  const callback = (key) => {
    setDefaultKey(key);
  };

  /**
   * test the password provided if its meets our password criteria
   * @param {string} password
   */

  const doChecks = (password) => {
    let hasNumber = /\d/g.test(password) ? 1 : 0;
    let hasUppercase = /[A-Z]/g.test(password) ? 1 : 0;
    let has8 = password.length >= 8 ? 1 : 0;
    let hasLength = password.length ? 1 : 0;
    let hasSpecialCharacter = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?~]/.test(
      password
    )
      ? 1
      : 0;

    if (hasUppercase && hasNumber && has8 && hasLength && hasSpecialCharacter) {
      setIsGoodPassword(true);
    } else {
      setIsGoodPassword(false);
    }
  };

  const changePwrd = () => {
    const { newpwrd, newPwrd2 } = newPasswords;
    if (isGoodPasword === false) {
      return;
    }
    if (newpwrd === newPwrd2 && newpwrd !== "") {
      setLoading(true);
      changePassword({ password: newpwrd })
        .then((res) => {
          setnewPasswords({
            newpwrd: "",
            newPwrd2: "",
          });
          setShowAdd(false);
          openAlert(true, "success", "Password changed successfully");
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      openAlert(true, "error", "Password do not match");
    }
  };

  // const updateUserDetails = (n) => {
  //   let data = n === 1 ? updateForm : editData;
  //   if (Object.values(data).length){
  //     n === 1 ? setLoading(true) : setLoading_(true);
  //     editMerchant( data , currentMerchant.businessid)
  //       .then((res) => {
  //         openAlert(true, "success", "Details updated successfully");
  //         // setUpdateForm({});
  //         // updateEditData({});
  //         fetchMerchantData();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //         setLoading_(false);
  //       });
  //   }
  // };

  const fetchMerchantData = () => {
    fetchMerchant(currentMerchant.businessid)
      .then((res) => {
        updateCurrentMerchant({ ...currentMerchant, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPrivateKey = () => {
    setFetchingKey(true);
    fetchMerchantKey(currentMerchant.businessid)
      .then((res) => {
        setPrivatekey(res.data.privatekey);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setFetchingKey(false));
  };

  return (
    <div className="settings" style={{ marginTop: -60 }}>
      <AppAlert
        show={showAlert.show}
        type={showAlert.type}
        description={showAlert.description}
      />
      <div
        className="white-bg"
        style={{
          backgroundColor: "#fff",
          borderRadius: 10,
          padding: "20px 30px",
        }}
      >
        <Tabs defaultActiveKey={defaultKey} onChange={callback}>
          <TabPane tab="General" key="1">
            <div className="form" style={{ width: 600 }}>
              <Row gutter={20}>
                <Col xs={24} md={12}>
                  <p style={{ fontSize: 14, marginBottom: 3 }} className="bw-m">
                    Personal Information
                  </p>
                  <Input
                    bordered={false}
                    disabled
                    placeholder="Firstname"
                    defaultValue={currentMerchant.contactfirstname}
                    value={updateForm.contactfirstname}
                    onChange={(e) => {
                      const firstname = e.target.value.replace(
                        /[^a-zA-Z\s]/,
                        ""
                      );
                      setUpdateForm({
                        ...updateForm,
                        contactfirstname: firstname,
                      });
                    }}
                    style={{ width: "100%", height: 45, marginBottom: 20 }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <p
                    style={{ fontSize: 14, opacity: 0, marginBottom: 3 }}
                    className="bw-m"
                  >
                    l
                  </p>
                  <Input
                    bordered={false}
                    disabled
                    placeholder="Lastname"
                    defaultValue={currentMerchant.contactlastname}
                    value={updateForm.contactlastname}
                    onChange={(e) => {
                      const lastname = e.target.value.replace(
                        /[^a-zA-Z\s]/,
                        ""
                      );
                      setUpdateForm({
                        ...updateForm,
                        contactlastname: lastname,
                      });
                    }}
                    style={{ width: "100%", height: 45, marginBottom: 20 }}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <p style={{ fontSize: 14, marginBottom: 3 }} className="bw-m">
                    Email Address
                  </p>
                  <Input
                    bordered={false}
                    disabled
                    value={user.email}
                    style={{ width: "100%", height: 45, marginBottom: 20 }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <p style={{ fontSize: 14, marginBottom: 3 }} className="bw-m">
                    Phone number
                  </p>
                  <Input
                    type="number"
                    disabled
                    bordered={false}
                    defaultValue={currentMerchant.businessphone}
                    value={updateForm.businessphone}
                    onChange={(e) => {
                      setUpdateForm({
                        ...updateForm,
                        businessphone: e.target.value,
                      });
                    }}
                    style={{ width: "100%", height: 45, marginBottom: 20 }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <p
                    style={{ fontSize: 14, marginBottom: 3, opacity: 0 }}
                    className="bw-m"
                  >
                    Password
                  </p>
                  <Button
                    style={{
                      padding: "0 30px",
                      height: 45,
                      borderRadius: 10,
                      marginBottom: 20,
                      backgroundColor: "#EEE",
                      color: "#363636",
                      border: 0,
                      width: "100%",
                    }}
                    onClick={() => setShowAdd(true)}
                  >
                    Change Password
                  </Button>
                </Col>

                {/* <Col xs={24} md={12}>
                  <Button
                    className='dark-btn'
                    style={{
                      padding: "0 30px",
                      height: 45,
                      borderRadius: 10,
                      marginBottom: 20,
                    }}
                    loading={loading}
                    onClick={() => updateUserDetails(1)}
                  >
                    SAVE
                  </Button>
                </Col> */}
                {/* <div className='userwrap'>
                   <img src={userIcon} alt='' />
                </div> */}
              </Row>
            </div>
          </TabPane>
          <TabPane tab="API" key="2">
            <p className="bw-m" style={{ fontSize: 14, marginBottom: 3 }}>
              Public Key
            </p>
            <Input
              style={{ width: 500 }}
              suffix={
                <CopyToClipboard
                  text={currentMerchant.publickey}
                  onCopy={() => openAlert(true, "success", "Public key copied")}
                >
                  <span
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#09427D",
                      margin: "5px 0",
                      borderRadius: 5,
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Copy Key
                  </span>
                </CopyToClipboard>
              }
              value={currentMerchant.publickey}
            />
            <p
              className="bw-m"
              style={{ fontSize: 14, marginBottom: 3, marginTop: 20 }}
            >
              Secret Key
            </p>
            <Input
              style={{ width: 500 }}
              value={privatekey}
              suffix={
                <>
                  {!privatekey ? (
                    <Button
                      style={{
                        padding: "10px 25px",
                        backgroundColor: "#09427D",
                        margin: "5px 0",
                        borderRadius: 5,
                        color: "#fff",
                        cursor: "pointer",
                        height: 45,
                      }}
                      loading={fetchingKey}
                      onClick={getPrivateKey}
                    >
                      Get key
                    </Button>
                  ) : (
                    <CopyToClipboard
                      text={privatekey}
                      onCopy={() =>
                        openAlert(true, "success", "Private key copied")
                      }
                    >
                      <span
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#09427D",
                          margin: "5px 0",
                          borderRadius: 5,
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        Copy Key
                      </span>
                    </CopyToClipboard>
                  )}
                </>
              }
            />
          </TabPane>
          <TabPane tab="Bank Details" key="3">
            <p className="bw-m" style={{ fontSize: 14, marginBottom: 3 }}>
              Account Number
            </p>
            <Input
              disabled={bankDataVerified}
              style={{ width: 500, height: 45, marginBottom: 20 }}
              defaultValue={
                currentMerchant.bank ? currentMerchant.bank.number : ""
              }
              onChange={(e) =>
                setBankData({
                  ...bankData,
                  accountnumber: e.target.value,
                })
              }
            />

            <p className="bw-m" style={{ fontSize: 14, marginBottom: 3 }}>
              Bank
            </p>
            <Select
              showSearch
              disabled={bankDataVerified}
              onSearch={onSearchChange}
              bordered={false}
              style={{ width: 500, height: 45, marginBottom: 20 }}
              defaultValue={
                currentMerchant.bank
                  ? currentMerchant.bank.code +
                    "|" +
                    getBankName(currentMerchant.bank.code)
                  : ""
              }
              onChange={(e) => {
                setBankData({
                  ...bankData,
                  bankcode: e,
                });
              }}
            >
              {filteredBanks.map((bank, idx) => (
                <Select.Option key={idx} value={bank.code + "|" + bank.name}>
                  {bank.name}
                </Select.Option>
              ))}
            </Select>
            {bankDataVerified && (
              <>
                <p className="bw-m" style={{ fontSize: 14, marginBottom: 3 }}>
                  Account Name
                </p>
                <Input
                  style={{ width: 500, height: 45, marginBottom: 20 }}
                  value={bankName}
                />
              </>
            )}
            <div>
              {bankDataVerified ? (
                <>
                  <Button
                    className="dark-btn"
                    style={{
                      padding: "0 30px",
                      height: 45,
                      borderRadius: 10,
                      marginBottom: 20,
                    }}
                    // disabled={bankDataVerified}
                    loading={loading}
                    onClick={updateBankDetails}
                  >
                    SAVE
                  </Button>

                  <Button
                    className="btn"
                    style={{
                      padding: "0 30px",
                      height: 45,
                      borderRadius: 10,
                      marginBottom: 20,
                      marginLeft: 10,
                    }}
                    onClick={() => setbankDataVerified(false)}
                  >
                    CANCEL
                  </Button>
                </>
              ) : (
                <Button
                  className="dark-btn"
                  style={{
                    padding: "0 30px",
                    height: 45,
                    borderRadius: 10,
                    marginBottom: 20,
                  }}
                  // disabled={bankDataVerified}
                  loading={loading}
                  onClick={verifyData}
                >
                  VERIFY
                </Button>
              )}
            </div>
            {currentMerchant.bank && currentMerchant.bank.underreview ? (
              <div
                style={{
                  marginTop: 20,
                  color: "red",
                  fontFamily: "BW-modelica-medium",
                  fontSize: 18,
                }}
              >
                You added this bank account recently. We take your security
                seriously, so we will be <br />
                calling you on the phone for confirmation before this new bank
                information can be used.
              </div>
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tab="Support" key="4">
            <Support />
          </TabPane>
        </Tabs>
      </div>

      {defaultKey === "3" && (
        <div className="white-bg">
          <div className="formidable">
            <span
              className="switch-text"
              style={{
                color: `${
                  checked
                    ? "rgba(54, 54, 54, 0.961)"
                    : "rgba(54, 54, 54, 0.247)"
                }`,
              }}
            >
              {TEXT}
            </span>
            <Switch onChange={onSwitchChange} checked={checked} />
          </div>
        </div>
      )}

      {defaultKey === "1" && (
        <div className="white-bg">
          <div className="form" style={{ width: 600 }}>
            <Row gutter={20}>
              <Col xs={24} md={24}>
                <p style={{ fontSize: 14, marginBottom: 3 }} className="bw-m">
                  Business Name
                </p>
                <Input
                  bordered={false}
                  disabled
                  defaultValue={currentMerchant.businessname}
                  // onChange={(e) =>
                  //   updateEditData({
                  //     ...editData,
                  //     businessname: e.target.value,
                  //   })
                  // }
                  style={{ width: "100%", height: 45, marginBottom: 20 }}
                />
              </Col>
              <Col xs={24} md={12}>
                <p style={{ fontSize: 14, marginBottom: 3 }} className="bw-m">
                  Business Email Address
                </p>
                <Input
                  bordered={false}
                  disabled
                  value={user.email}
                  onChange={(e) =>
                    updateEditData({
                      ...editData,
                      email: e.target.value,
                    })
                  }
                  style={{ width: "100%", height: 45, marginBottom: 20 }}
                />
              </Col>
              <Col xs={24} md={12}>
                <p style={{ fontSize: 14, marginBottom: 3 }} className="bw-m">
                  Business Industry
                </p>
                <Select
                  style={{ width: "100%", height: 45, marginBottom: 20 }}
                  bordered={false}
                  disabled
                  defaultValue={currentMerchant.industry}
                  onChange={(e) =>
                    updateEditData({
                      ...editData,
                      industry: e,
                    })
                  }
                >
                  {options.map((option, idx) => (
                    <Select.Option key={idx} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
                {/* <Input
                  defaultValue={currentMerchant.businessphone}
                  onChange={(e) =>
                    updateEditData({
                      ...editData,
                      businessphone: e.target.value,
                    })
                  }
                  bordered={false}
                  style={{ width: "100%", height: 45, marginBottom: 20 }}
                /> */}
              </Col>
              <Col xs={24} md={24}>
                <p style={{ fontSize: 14, marginBottom: 3 }} className="bw-m">
                  Business Address
                </p>
                <Input
                  bordered={false}
                  disabled
                  defaultValue={currentMerchant.address}
                  onChange={(e) =>
                    updateEditData({
                      ...editData,
                      address: e.target.value,
                    })
                  }
                  style={{ width: "100%", height: 45, marginBottom: 20 }}
                />
              </Col>
              {/* <Col xs={24} md={12}>
                <Button
                  className='dark-btn'
                  style={{
                    padding: "0 30px",
                    height: 45,
                    borderRadius: 10,
                    marginBottom: 20,
                  }}
                  loading={loading_}
                  onClick={() => updateUserDetails(2)}
                  // onClick={handleEdit}
                >
                  SAVE
                </Button>
              </Col> */}
            </Row>
          </div>
        </div>
      )}

      <Modal
        title="Change Password"
        visible={showAdd}
        footer={false}
        closable={true}
        onCancel={() => setShowAdd(false)}
      >
        <div style={{ padding: 15 }}>
          <p className="label bw-m" style={{ marginBottom: 3 }}>
            New Password
          </p>
          <Input.Password
            bordered={false}
            value={newPasswords.newpwrd}
            onChange={(e) => {
              doChecks(e.target.value);
              setnewPasswords({
                ...newPasswords,
                newpwrd: e.target.value,
              });
            }}
            style={{ width: "100%", height: 50, marginBottom: 20 }}
          />
          {!isGoodPasword && newPasswords.newpwrd.length >= 1 && (
            <p className="caveat" style={{ color: "red" }}>
              Password must have at least one number
              <br />
              Password must have at least one special character
              <br />
              Password must have an Uppercase character
              <br />
              Password Length must be up to 8<br />
            </p>
          )}
          <p className="label bw-m" style={{ marginBottom: 3 }}>
            Confirm Password
          </p>
          <Input.Password
            bordered={false}
            value={newPasswords.newPwrd2}
            onChange={(e) =>
              setnewPasswords({
                ...newPasswords,
                newPwrd2: e.target.value,
              })
            }
            style={{ width: "100%", height: 50, marginBottom: 20 }}
          />

          <div className="buttons" style={{ marginTop: 20 }}>
            <Button
              className="dark-btn"
              onClick={changePwrd}
              loading={loading}
              style={{
                padding: "0 20px",
                borderRadius: 5,
                height: 50,
                width: "100%",
              }}
            >
              CHANGE PASSWORD
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
