import {useState} from 'react';

export const useShowAlert = () => {
    const [showAlert, setshowAlert] = useState({
        show: false,
        type: "",
        description: "",
    })

    const closeAlert = () => {
        setshowAlert({
          show: false,
          type: "success",
        });
      };
    
      const openAlert = (show, type, description) => {
        setshowAlert({
          show,
          type,
          description,
        });
        setTimeout(() => {
          closeAlert();
        }, 3000);
      };

      return { showAlert, openAlert };
}