import { HashRouter, Route, Switch } from "react-router-dom";
import AppContextProvider from "./store/appContext";
// import SnackbarProvider from "react-simple-snackbar";
import Home from "./views/Home/Home";
import "./App.scss";

// ant design
import "antd/dist/antd.css";

// fonts
import "./styles/fonts.css";
import Signup from "./views/Auth/Signup";
import Login from "./views/Auth/Login";
import Dashboard from "./views/Dashboard/Dashboard";
import ContactUs from './views/Contact-Us/Contact-Us'
import AboutUs from './views/AboutUs/AboutUs'
import TermsAndConditions from './views/TermsAndConditions/TermsAndConditions'
import AuthGuard from "./components/Auth/AuthGuard";

function App() {
  return (
    <HashRouter>
      {/* <SnackbarProvider> */}
      <AppContextProvider>
        <div className='app'>
          <Switch match>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='/signup'>
              <Signup />
            </Route>
            <Route path='/login'>
              <Login />
            </Route>
            <Route path='/dashboard'>
              <AuthGuard>
                <Dashboard />
              </AuthGuard>
            </Route>
            <Route path='/t&c'>
              <TermsAndConditions/>
            </Route>
            <Route path='/contact'>
              <ContactUs/>
            </Route>
            <Route path='/about'>
              <AboutUs/>
            </Route>
          </Switch>
        </div>
      </AppContextProvider>
      {/* </SnackbarProvider> */}
    </HashRouter>
  );
}

export default App;
