import React, { useEffect, useState } from "react";
import "./Home.scss";

import AppAlert from '../../components/Alert/AppAlert'

import logo from "../../assets/logoIcon.svg";
import customer from "../../assets/customer.svg";
import sales from "../../assets/sales.svg";
import warning from "../../assets/warning.svg";
import trustpilot from "../../assets/trustpilot.svg";

import sm1 from "../../assets/sm1.png";
import sm2 from "../../assets/sm2.png";
import sm3 from "../../assets/sm3.png";
import sm4 from "../../assets/sm4.png";
import bg1 from "../../assets/bg1.png";
import mobileImage from "../../assets/mobileImage.png";
import Ellipse8 from "../../assets/Ellipse-8.svg";
import Ellipse6 from "../../assets/Ellipse-6.svg";
import Ellipse7 from "../../assets/Ellipse-7.svg";
import googlePlay from '../../assets/googlePlay.svg'
import appleStore from '../../assets/appleStore.svg'

import { Col, Row } from "antd";
import { StarFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import AppFooter from "../../components/Footer";
import { useRef } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { useShowAlert } from "../../hooks/useShowAlert";

export default withRouter(function Home({ history }) {
  const myRef = useRef(null);
  const {showAlert, openAlert} = useShowAlert();
  const executeScroll = () => scrollToRef(myRef);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const [token, settoken] = useState(sessionStorage.getItem("v_pay_token"));
  useEffect(() => {
    token && history.push("/dashboard/");
  }, []);

  return (
    <div className='home'>
      <div className="con">
       <AppAlert
        show={showAlert.show}
        description={showAlert.description}
      />
      <div className="ak">
      <div className='hero'>
        <div className='nav'>
          <div className='logo'>
            <img src={logo} alt='' />
            <span className='bw-b'>
              Dart <span className='green'>Pay</span>
            </span>
            <small>For Merchants</small>
          </div>
          <div className='links'>
            <div className='link bw-m' onClick={executeScroll}>
              Pricing
            </div>
    
              <Link to='/login' className="link">
                <span>Login</span>
              </Link>

              <Link to='/signup' className="link">
                <button className='sm-btn green-btn'>Create free account</button>
              </Link>
          </div>
        </div>

        <div className='hero-texts'>
          <h1>Receive Payments <br/>via Bank Transfer</h1>
          <p className='bw-m'>Confirm transfers instantly at points of sale</p>

          <Link to='/signup'>
            <button className='bg-btn green-btn'>Create free account</button>
          </Link>
            <button className='bg-btn white-btn' onClick={ () => openAlert(true, "success" , "Video coming soon")}> <span style={{ marginRight : 5 }}>&#9654;</span>Watch Video</button>
        </div>
      </div>
      </div>
     
     <div className="ak">
      <div className='gains'>
        <p className='purple--text'>What do you gain from using DartPay?</p>
        <Row
          style={{
            maxWidth: "calc(100vw - 170px)",
          }}
        >
          <Col sm={8}>
            <div className='gain-sect'>
              <img src={customer} alt='' />
              <p className='sect-title bw-b'>New Customers</p>
              <p className='sect-desc bw-m'>
                Reach out to a large audience
                <br />
                of DartPay customers and shoppers
                <br />
                who don’t pay with bank cards.
              </p>
            </div>
          </Col>
          <Col xs={8}>
            <div className='gain-sect'>
              <img src={sales} alt='' />
              <p className='sect-title bw-b'>Increased Purchases</p>
              <p className='sect-desc bw-m'>
                Attract a greater number of shoppers
                <br />
                who are more comfortable with making
                <br />
                payments via bank transfers.
              </p>
            </div>
          </Col>
          <Col xs={8}>
            <div className='gain-sect'>
              <img src={warning} alt='' />
              <p className='sect-title bw-b'>Zero Risk</p>
              <p className='sect-desc bw-m'>
                We assume all risk. You get paid
                <br />
                in full, immediately a customer’s
                <br />
                bank transfer occurs.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      </div>

      <div className="ak">
      <div className='info-grid'>
        <Row className='temp-row'>
            <div className='images'>
              <img src={Ellipse6} alt='' className='el6' />
              <img src={Ellipse7} alt='' className='el7' />
              <img src={Ellipse8} alt='' className='el8' />

              <img src={sm1} alt='' className='sm1' />
              <img src={sm2} alt='' className='sm2' />
              <img src={sm3} alt='' className='sm3' />
              <img src={bg1} alt='' className='sm4' />
              <img src={sm4} alt='' className='bg1' />
            </div>
          <div>
            <p className='purple--text'>DartPay Is About More Than Selling</p>
            <p className='info-desc bw-m'>
               We are here to provide a much more seamless
              <br />
              payment experience your buyers.
              Improve <br/>customer checkout by eliminating the delay<br/>
              associated with sales attendants trying to<br/>
              verify payments made via bank transfer.
            </p>
          </div>
        </Row>

        <Row
          style={{
            alignItems: "flex-end",
            margin: "60px 0px",
            justifyContent: "space-between",
            maxWidth: 1500
          }}
        >
          <div style={{ marginTop: 60 }}>
            <p
              className='purple--text'
              style={{ width: "100%", marginBottom: "10px" }}
            >
              Top rated payment method
            </p>
            <p
              className='rating-desc bw-m'
              style={{
                color: "#47A08B",
                fontSize: "12px",
              }}
            >
              But don’t just take our word for it, see what buyers are saying.
            </p>

            <div className='ratings'>
              <StarFilled
                style={{ color: "#47A08B", fontSize: 20, marginRight: 10 }}
              />
              <StarFilled
                style={{ color: "#47A08B", fontSize: 20, marginRight: 10 }}
              />
              <StarFilled
                style={{ color: "#47A08B", fontSize: 20, marginRight: 10 }}
              />
              <StarFilled
                style={{ color: "#47A08B", fontSize: 20, marginRight: 10 }}
              />
              <StarFilled style={{ color: "#E0E0E0", fontSize: 20 }} />
            </div>
            <p className='info-desc bw-m'>
              DartPay is tha bomb! Love that I can finally
              <br />
              pay for everythingfrom my bank account.
            </p>
            <p className='who  bw-m' style={{ color: "#47A08B" , marginTop: 5}}>
              Mr. Andrew Okoli
            </p>
            <div className='stores' style={{ marginTop: 30 }}>
              <a href="merchantapp.dartpay.cash" target="_blank" rel="noopener noreferrer">
             <img src={googlePlay} alt="Google play store" style={{ height: 50 , marginRight: 10, cursor: "pointer"}}/>
              </a>
              <a href="merchantapp.dartpay.cash" target="_blank" rel="noopener noreferrer">
              <img src={appleStore} alt="Apple store" style={{ height: 50, cursor: "pointer" }}/>
              </a>
            </div>
          </div>
          <div style={{ marginTop: 60 }}>
            <div className='images'>
              <img
                src={mobileImage}
                alt=''
                style={{
                  height: "400px",
                }}
              />
            </div>
          </div>
        </Row>
      </div>
      </div>

      <div
        className='pilot-section'
        style={{
          padding: "80px 170px 20px 170px",
        }}
      >
        {/*<p>
          Rated <span className='bw-m'>4.8</span> out of{" "}
          <span className='bw-m'>5</span> based on{" "}
          <span className='bw-m'>3,800+</span> reviews on
          <img src={trustpilot} alt='' />
        </p>*/}
      </div>

      <div className='fees' ref={myRef}>
        <h2>
          Fixed Transaction Fee. <br /> Today, Always.
        </h2>
        <h4>
          <span className='bw-b'>0.5% + ₦100</span> Per Transaction. Max: ₦1,000
        </h4>
        {/* <Link to='/login'>
          <button className='sm-btn yellow-btn'>SEE OUR MERCHANTS</button>
        </Link> */}
      </div>

      <div className='ready'>
        <p className='purple--text'>Ready, Set, DartPay!</p>
        <p className='ready-desc bw-m'>
          Sign up today and start receiving payments from over of 50% of your
          untapped customer base. Transaction notifications are instant.
        </p>
        <Link to='/signup' style={{
          marginTop: '20px'
        }}>
          <button className='green-btn sm-btn'>Create free account</button>
        </Link>
      </div>

      <AppFooter />
    </div>
    </div>
  );
});
