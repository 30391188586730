import { Select, Input, Radio, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";

const TransferForm = ({
  setStep,
  onSearchChange,
  filteredBanks,
  bankDataVerified,
  bankName,
  setTransferData,
  transferData,
  verifyData,
  loading,
  setbankDataVerified,
  getBankName,
  setSaveBenefiary,
  flag,
}) => {
  const handleContinue = () => {
    if (transferData.amount) {
      setStep(2);
    }
  };
  return (
    <div className="d-wrapper">
      <div className="row-con">
        <p className="label">Bank</p>
        <Select
          disabled={bankDataVerified}
          showSearch
          value={
            transferData.bankcode &&
            (
              transferData.bankcode +
              "|" +
              getBankName(transferData.bankcode)
            ).split("|")[1]
          }
          bordered={false}
          style={{ height: 55, width: "100%" }}
          onChange={(e) => {
            setTransferData({
              ...transferData,
              bankcode: e,
            });
          }}
          onSearch={onSearchChange}
        >
          {filteredBanks.map((bank, idx) => (
            <Select.Option key={idx} value={bank.code + "|" + bank.name}>
              {bank.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="row-con">
        <p className="label">Account Number</p>
        <Input
          disabled={bankDataVerified}
          bordered={false}
          style={{ height: 55, width: "100%" }}
          type="number"
          value={transferData.accountnumber}
          onChange={(e) => {
            setTransferData({
              ...transferData,
              accountnumber: e.target.value,
            });
          }}
        />
      </div>
      {bankDataVerified === false ? (
        <Button
          className="dark-btn"
          style={{
            width: "100%",
            height: 50,
            borderRadius: 5,
            marginTop: 20,
          }}
          onClick={verifyData}
          loading={loading}
        >
          Verify Account
        </Button>
      ) : (
        <>
          <div className="row-con">
            <p style={{ margin: "10px 0px", color: "#09427D" }}>{bankName}</p>
          </div>
          <div className="row-con">
            <p className="label">Amount</p>
            <Input
              bordered={false}
              style={{ height: 55, width: "100%" }}
              value={transferData.amount}
              onChange={(e) => {
                setTransferData({
                  ...transferData,
                  amount: e.target.value,
                });
              }}
              type="number"
            />
          </div>
          <div className="row-con">
            <p className="label">Description</p>
            <TextArea
              bordered={false}
              value={transferData.description}
              onChange={(e) => {
                setTransferData({
                  ...transferData,
                  description: e.target.value,
                });
              }}
              style={{
                maxHeight: 150,
                width: "100%",
                minHeight: 50,
                height: 150,
              }}
            />
          </div>
          {flag && (
            <div className="save-ben">
              <span style={{ fontSize: 16, color: "#09427D" }}>
                Save beneficiary
              </span>
              <Radio.Group
                style={{ width: "68%" }}
                onChange={(e) => {
                  setSaveBenefiary(e.target.value);
                }}
              >
                <Radio value="yes" style={{ color: "#09427D" }}>
                  Yes
                </Radio>
                <Radio value="no" style={{ color: "#09427D" }}>
                  No
                </Radio>
              </Radio.Group>
            </div>
          )}
          <div style={{ marginTop: 30 }}>
            <Button
              onClick={() => setbankDataVerified(false)}
              style={{
                width: "45%",
                height: 50,
                borderRadius: 5,
                marginRight: "10%",
                color: "#fff",
                fontWeight: "bold",
                backgroundColor: "#7a86734a",
                border: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              className="dark-btn"
              style={{
                width: "45%",
                height: 50,
                borderRadius: 5,
                fontWeight: "bold",
              }}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default TransferForm;
