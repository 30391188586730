import { Button, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { useShowAlert } from "../../../hooks/useShowAlert";
import AppAlert from "../../../components/Alert/AppAlert";
import { contactSupport } from "../../../services/apiSevices";

import "./Support.scss";

export default function Support() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [formFields, setformFields] = useState({
    subject: "",
    message: "",
  });
  
  const {showAlert, openAlert} = useShowAlert()
  const submitHandler = () => {
    if (!formFields.message || !formFields.subject) {
      setHasError(true);
      return;
    }
    setLoading(true);
    contactSupport()
      .then((res) => {
        // console.log(res);
        setformFields({
           subject: "",
           message: "",
        })
        openAlert(true, "success", "Message sent successfully");
      })
      .catch((err) => {
        console.log(err);
        openAlert(true, "error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };



  return (
    <div className='support'>
      <AppAlert
        show={showAlert.show}
        type={showAlert.type}
        description={showAlert.description}
      />
      <div className='white-bg'>
        <div className='page-heading'>Reach Out To Us</div>
        <div className='page-sub-heading bw-m'>
          24x7, we’ll answer your calls and provide the desired resolution to
          your concerns.
        </div>
        <p className='label'>Submit A Request</p>
        <Input
          placeholder='Subject'
          style={{ height: 50 }}
          className={`${hasError && !formFields.subject ? "has-error" : ""}`}
          value={formFields.subject}
          onChange={(e) =>
            setformFields({
              ...formFields,
              subject: e.target.value,
            })
          }
        />

        <p className='label'>Write A Message</p>
        <TextArea
          style={{ marginBottom: 30, maxHeight: '300px' , minHeight: '120px' }}
          className={`${hasError && !formFields.message ? "has-error" : ""}`}
          value={formFields.message}
          onChange={(e) =>
            setformFields({
              ...formFields,
              message: e.target.value,
            })
          }
        />

        <Button loading={loading} onClick={submitHandler} className='dark-btn'>
          SUBMIT
        </Button>
      </div>
    </div>
  );
}
