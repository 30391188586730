import React, { useContext, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./Auth.scss";
import logo from "../../assets/logoIcon.svg";
import { Button, Col, Input, Row, Modal } from "antd";
import AppFooter from "../../components/Footer";
import { merchantLogin, resetPassword } from "../../services/apiSevices";
import { userContext } from "../../store/appContext";
import axios from "axios";
import AppAlert from "../../components/Alert/AppAlert";
import { useShowAlert } from "../../hooks/useShowAlert";
// import { TrophyOutlined } from "@ant-design/icons";

// import {baseUrl} from '../../services/urls'

export default withRouter(function Login({ history }) {
  const context = useContext(userContext);
  const {showAlert, openAlert} = useShowAlert()
  const { updateUser, updateCurrentMerchant } = context;

  const [resetMail, setResetMail] = useState("");

  const [modalLoader, setModalLoader] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [token, settoken] = useState(sessionStorage.getItem("v_pay_token"));
  useEffect(() => {
    token && history.push("/dashboard/");
  }, []);


  const handleForgotPassword = () => {
    setModalLoader(true);
    let data = {
      email: resetMail,
    };
    resetPassword(data)
      .then((res) => {
        let status = res.status;
        setModalLoader(false);
        if (status === false) {
          openAlert(true, "error", "Could not complete request");
        } else {
          setShowAdd(false);
          setResetMail("");
          openAlert(
            true,
            "success",
            "New Password has been sent to the Email provided"
          );
        }
      })
      .catch((err) => {
        setModalLoader(false);
        openAlert(true, "error", err.response.data.message);
      });
  };

  const loginHandler = () => {
    if (!email || !password) {
      setErrors(true);
      return;
    }

    setLoading(true);
    let data = {
      email,
      password,
      app: "merchant",
    };
    merchantLogin(data)
      .then((res) => {
        var decoded = jwt_decode(res.data.token);
        setLoading(false);
        if (!decoded.businessid) {
          openAlert(true, "error", "Could not complete request");
        } else {
          sessionStorage.setItem("v_pay_token", res.data.token);
          axios.defaults.headers.accessToken = res.data.token;
          axios.defaults.headers["b-access-token"] = res.data.token;

          let payload = {
            ...res.data,
            ...decoded,
          };
          updateCurrentMerchant(payload.merchants[0]);
          updateUser(payload);

          history.push("/dashboard");
        }
      })
      .catch((err) => {
        setLoading(false);
        openAlert(true, "error", err.response.data.message);
      });
  };

  const checkEnter = (e) => {
    if (e.key === "Enter" || e.code === "Enter") loginHandler();
  };

  return (
    <div className="auth">
      <AppAlert
        show={showAlert.show}
        type={showAlert.type}
        description={showAlert.description}
      />
      <div className="nav">
        <Link to="/">
          <span className="logo-wrap">
            <img src={logo} alt="" style={{ width: 40 }} />
            <span className="bw-b">
              Dart <span className="green">Pay</span>
            </span>
            <small
              style={{
                left: "42px",
              }}
            >
              For Merchants
            </small>
          </span>
        </Link>
        <p className="already bw-m">
          Don't have an account?{" "}
          <Link to="/signup">
            {" "}
            <span>Sign Up</span>{" "}
          </Link>
        </p>
      </div>

      <div className="form-content">
        <p className="form-title" style={{ marginTop: 40 }}>
          Welcome! As a merchant, nothing should keep you away from your DartPay
          dashboard.
        </p>
        <p className="form-subtitle bw-m">
          Log in to keep track of your transactions on the go.
        </p>
        <Row gutter={20}>
          <Col sm={24} md={24}>
            <p className="label bw-m  rq">Business Contact</p>
            <Input
              placeholder="Email Address"
              style={{ height: 55 }}
              bordered={false}
              size="large"
              defaultValue={email}
              onKeyDown={checkEnter}
              className={errors && !email && "has-error"}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
          <Col sm={24} md={24}>
            <p className="label bw-m  rq">Password</p>
            <Input.Password
              placeholder="Password"
              style={{ height: 55, width: "100%" }}
              bordered={false}
              size="large"
              onKeyDown={checkEnter}
              defaultValue={password}
              className={errors && !password && "has-error"}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Col>

          <Col sm={24} md={24}>
            <p
              className="label bw-m"
              style={{
                cursor: "pointer",
                width: "fit-content",
              }}
              onClick={() => setShowAdd(true)}
            >
              Forgot Password?
            </p>
          </Col>

          <Col sm={24} md={24}>
            <Button
              loading={loading}
              className="dark-btn"
              style={{
                width: "100%",
                height: 50,
                borderRadius: 5,
                marginTop: 30,
                marginBottom: 30,
              }}
              onClick={loginHandler}
            >
              LOGIN AS MERCHANT
            </Button>
          </Col>
        </Row>
      </div>
      <div style={{ textAlign: "center", color: "#c2c2c2", fontSize: 20 }}>
        {process.env.REACT_APP_VERSION_NUMBER}
      </div>
      <AppFooter />

      <Modal
        title="Reset Password"
        visible={showAdd}
        footer={false}
        closable={true}
        onCancel={() => setShowAdd(false)}
      >
        <div style={{ padding: 15 }}>
          <p className="label bw-m" style={{ marginBottom: 3 }}>
            Enter Your E-Mail
          </p>
          <Input
            bordered={false}
            placeholder="Email Address"
            value={resetMail}
            style={{ width: "100%", height: 50, marginBottom: 20 }}
            onChange={(e) => setResetMail(e.target.value)}
          />

          <div
            className="buttons"
            style={{ textAlign: "right", marginTop: 30 }}
          >
            <button
              className="white-btn_"
              style={{
                padding: "0 20px",
                borderRadius: 5,
                height: 50,
                marginRight: 20,
              }}
              onClick={() => setShowAdd(false)}
            >
              CANCEL
            </button>
            <Button
              className="dark-btn"
              onClick={handleForgotPassword}
              loading={modalLoader}
              style={{ padding: "0 20px", borderRadius: 5, height: 50 }}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
});
