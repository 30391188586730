import React from 'react'
import {Link} from 'react-router-dom'

import Footer from '../../components/Footer'

import logo from '../../assets/logoIcon.svg'

import './TermsAndConditions.scss'

export default function TermsAndConditions(){


     return(
        <div className='home'>
          <div className='nav'>
          <div className='logo'>
            <Link to="/">
             <img src={logo} alt='' />
            <span className='bw-b'>
              Dart <span className='green'>Pay</span>
            </span>
            </Link>
          </div>
          <div className='links'>
            <div className='link bw-m'>
              <Link to='/login'>
                <span style={{ color: "#000000" }}>Login</span>
              </Link>
            </div>
            <div className='link'>
              <Link to='/signup'>
                <button className='sm-btn green-btn'>Register</button>
              </Link>
            </div>
          </div>
        </div>
          <div className="tc-title">
             <h2>DartPay Terms & Conditions</h2>
          </div>
          <div className="terms-content">
            <div style={{fontFamily: 'Raleway', fontSize: 13}}>
              <p className="western" align="justify">
              {/* <p align="justify">&nbsp;</p> */}
                <span style={{color: '#003366'}}>
                  <strong>Introduction</strong>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>These Terms and Conditions ("Terms") contained herein on this page is a legal agreement between you, as a prospective customer and or customer of </span>
                <span>
                  <span lang="en-US">DartPay</span>
                </span>
                <span> services and </span>
                <span>
                  <span lang="en-US">DartPay </span>
                </span>
                <span> (</span>
                <span>
                  <span lang="en-US">DartPay</span>
                </span>
                <span>, "we", "our" or "us") and shall govern your access to and use of DartPay's services which include all pages within the DartPay website, mobile applications and other products and services (collectively referred to as the "Services").</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>These Terms apply in full force and effect to your use of the Services and by using any of the Services, you expressly accept all terms and conditions contained herein in full and without limitation or qualification, including our Privacy Policy. You must not use any of the Services, if you have any objection to any of these Terms.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Who We Are And Our Company Information</strong>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>We are DartPay, a product of Minerva Technologies Company Limited; a company registered under the laws of </span>
                <span>
                  <span lang="en-US">Federal Republic of Nigeria</span>
                </span>
                <span>. Our registered office is at </span>
                <span>
                  <span lang="en-US">22A Prof Kumi Akingbehin Street, Lekki, Lagos.</span>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>PLEASE READ AND UNDERSTAND THE TERMS OF AGREEMENT CAREFULLY BEFORE AGREEING TO BE BOUND BY ITS TERMS.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Governing Language</strong>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>The governing language of these terms and all communication between DartPay and you will be English language.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Who May Use Our Services?</strong>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>You may use the Services only when you agree to form a binding contract with DartPay and are not a person barred from receiving services under the laws of the applicable jurisdiction. If you are accepting these Terms and use the Services on behalf of a company, business, or organization, you represent and warrant that you are authorized to do so.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Account</strong>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>
                  <u>Opening a DartPay Account</u>
                </span>
              </p>
              <p className="western" align="justify">
                <span>To use DartPay, you are required to register and open a DartPay Account with us by providing the necessary information needed to process you for the use of this service which you agree to.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>We offer DartPay accounts under the following classifications:</span>
              </p>
              <p className="western" align="justify">
                  &bull;&nbsp;<span lang="en-US">Merchant</span>
                <span>
                  <span lang="en-US">Service Accounts</span>
                </span>
              </p>
              <p className="western" align="justify">
                  &bull;&nbsp;<span lang="en-US">Cashier</span>
                <span> Service Accounts</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Buyer Wallet Accounts</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>To create a </span>
                <span>
                  <span lang="en-US">Merchant</span>
                </span>
                <span> account, you must be at least 18 years old.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>
                  <span lang="en-US">Merchant</span>
                </span>
                <span> accounts let you do things like:</span>
              </p>
              <p align="justify">
                  &bull;&nbsp;<span lang="en-US">Manage your business transactions</span>
              </p>
              <p align="justify">
                  &bull;&nbsp;<span lang="en-US">Manage Cashiers under your Merchant Account</span>
              </p>
              <p align="justify">
                  &bull;&nbsp;<span lang="en-US">Receive Payments accruing to every Cashier under your Merchant account</span>
              </p>
              <p align="justify">&nbsp;</p>
              <p align="justify">
                <span>To register, open and use a DartPay Account, you must sign up, create an account and provide necessary information including but not limited to your name, email address, mobile phone number, street address, </span>
                <span>
                  <span lang="en-US">company name</span>
                </span>
                <span> etc. (hereinafter referred to as &ldquo;Account Information&rdquo;). You represent and warrant that all Account Information provided is true and accurate.</span>
              </p>
              <p align="justify">&nbsp;</p>
              <p align="justify">
                <span>You hereby authorize DartPay, directly or through third parties, to make any inquiries we consider necessary to validate your identity and/or authenticate your identity and Account Information. This may include asking you for further information and/or documentation about your Account usage or identity, requiring you to take steps to confirm ownership of your email address, mobile phone number or financial instruments, or verifying your information against third party databases or through other sources.</span>
              </p>
              <p align="justify">&nbsp;</p>
              <p align="justify">
                <span>In connection with your use of the Service, you may not refuse to (a) cooperate in an investigation concerning activity that potentially violates the law and/or the Service Terms, (b) provide confirmation of your identity, or (c) provide confirmation of any information you provide us.</span>
              </p>
              <p align="justify">&nbsp;</p>
              <p align="justify">
                <span>You agree that you are responsible for safeguarding your DartPay</span>
                <span>
                  <span lang="en-US"> Account</span>
                </span>
                <span>, so, use a strong password and a unique code as your transaction PIN and limit it to use on your DartPay Account. Do not allow anyone to have or use your password or transaction PIN details. In the event that you share such details, we cannot and will not be liable for any loss or damage arising from your failure to comply with the above.</span>
              </p>
              <p align="justify">&nbsp;</p>
              <p align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Notifications</strong>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>By providing us with your Account Information, you consent to receiving text (SMS), email and push notifications from us.</span>
              </p>
              <p align="justify">&nbsp;</p>
              <p align="justify">
                <span style={{color: '#003366'}}>
                    <strong>Link or Unlink Bank Details</strong>
                </span>
              </p>
              <p className="western" lang="en-US" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>
                  <span lang="en-US">At the point of registration a Merchant Account, you</span>
                </span>
                <span>
                  <span lang="en-US">are expected to input your business bank account details. This is where all monies collected on your behalf, through DartPay, is paid to.</span>
                </span>
                <span> If this information changes, you </span>
                <span>
                  <span lang="en-US">are required to</span>
                </span>
                <span> update it</span>
                <span>
                  <span lang="en-US"> as DartPay will not be responsible for payments made to wrong bank account information.</span>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <span>
                    <span lang="en-US">
                      <strong>Creating CashPoints, Receiving</strong>
                    </span>
                  </span>
                  <span>
                    <strong> Money T</strong>
                  </span>
                  <span>
                    <span lang="en-US">
                      <strong>o</strong>
                    </span>
                  </span>
                  <span>
                    <strong> Your DartPay Account</strong>
                  </span>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>
                  <span lang="en-US">
                    <u>Creating CashPoints (Cashier Accounts)</u>
                  </span>
                </span>
              </p>
              <p className="western" align="justify">
                <span>
                  <span lang="en-US">A Merchant account holder can create as many CashPoints (Cashier Accounts) as possible to ensure seamless point of sales money collection. Funds collected at each CashPoint is also recorded on the Merchant Account&rsquo;s dashboard and ultimately remitted to the Merchant via the Bank details provided at the point of registration.</span>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>
                  <u>Receiving Money</u>
                </span>
              </p>
              <p className="western" align="justify">
                <span>Using DartPay, you can </span>
                <span>
                  <span lang="en-US">receive</span>
                </span>
                <span> payments from your customers through any </span>
                <span>
                  <span lang="en-US">CashPoint under your Merchant Account. </span>
                </span>
                <span>You can </span>
                <span>
                  <span lang="en-US">receive</span>
                </span>
                <span> money </span>
                <span>
                  <span lang="en-US">from your customers or clients</span>
                </span>
                <span>, even if they do not have a DartPay Account at the time </span>
                <span>
                  <span lang="en-US">of payment. They are simply required to pay by making a bank transfer to the bank details as specified on DartPay&rsquo;s CashPoint App.</span>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>
                  <u>Closing a DartPay Account</u>
                </span>
              </p>
              <p className="western" align="justify">
                <span>You may close your DartPay Account and terminate your relationship with us without cost, but you will remain liable for all obligations related to your DartPay Account even after the DartPay Account is closed. Any incomplete transactions or transfers must be completed or cancelled, and you must transfer any DartPay Account balance from your DartPay Account before closing it.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>In certain cases, you may not close your DartPay Account, including:</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>To evade an investigation.</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>If you have a pending transaction or an open dispute or claim.</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>If you owe amounts to us.</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>If your DartPay Account is subject to a hold, limitation or reserve.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>We may suspend or terminate your account or cease to provide you with all or part of the services at any time, including but not limited to, if we reasonably believe; (i) you do not comply with any of the provisions of this Agreement, (ii) you create risk or possible legal exposure to us, (iii) we are required by law to do so, and (iv) you have engaged in fraudulent or suspicious transactions.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Bank Transfer Reviews</strong>
                </span>
              </p>
              <p>&nbsp;</p>
              <p className="western" align="justify">
                <span>We review account and transaction activity at various times, including when bank transfers are initiated</span>
                <span>
                  <span lang="en-US"> by you to 3</span>
                </span>
                <sup>
                  <span>
                    <span lang="en-US">rd</span>
                  </span>
                </sup>
                <span>
                  <span lang="en-US"> parties</span>
                </span>
                <span>. This review checks for, among other things, suspicious or illegal activity, and whether your account activity and the activity of users with whom you've transacted comply with this agreement. In connection with our review process, you may be required to provide us with additional information and/or documentation to verify your identity. We may limit your account and your access to money in it or that is sent to you until verification is completed.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>Reviews may result in:</span>
              </p>
              <p className="western" align="justify">
                <br />
                &bull;&nbsp;<span>Delayed, blocked or cancelled transfers;</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Money or payments being held by us;</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Money or payments being applied to amounts you owe to us or used to offset loss incurred by us;</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Account limitation, suspension or termination;</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Money or payments being seized to comply with a court order, warrant or other legal process; and/or</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Money or payments you previously received being reversed (i.e. sent back to the sender or to the card or bank account that was used to fund the payment).</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>Among other reasons, we may take the above actions if you knowingly or unknowingly were a participant in a payment that was made from a stolen card, compromised bank account, or compromised DartPay Account</span>
                <span>
                  <span lang="en-US">.</span>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Publicity</strong>
                </span>
              </p>
              <p>&nbsp;</p>
              <p className="western" align="justify">
                <span>You grant DartPay permission to use your name and image in our marketing material without any further consent required, including, but not limited to use on our website, in promotional materials and in press releases. Such publicity does not in any way imply an endorsement for your products and services.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Refunds, Reversals and Chargebacks</strong>
                </span>
              </p>
              <p>&nbsp;</p>
              <p className="western" align="justify">
                <span>Payments may be invalidated and reversed by us if, among other reasons, a user sent the payment to you in error, the funding transaction is declined or reversed, the payment was unauthorized or unfunded, or if the payment was for activities that violated these Terms or any other agreement with us.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>As the recipient of a payment that is later invalidated for any reason, you may be liable to us for the full amount of the payment and we may recover the amount of the payment (plus any fees) from you. We may recover the amount of the payment from either the sender or the recipient of an invalidated payment in our discretion (subject to applicable law). </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>When recovering the amount of an invalidated payment from you, we may apply any money sent to you on DartPay, request that you add money to your account for the amount of the payment and apply that money to amounts owed, and/or we may:</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Engage in collection efforts to recover such amounts from you;</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Place a limitation or take other action on your DartPay Account</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>If you believe that a </span>
                <span>
                  <span lang="en-US">transaction</span>
                </span>
                <span> initiated with your DartPay account was not authorized, then you must notify us immediately.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>
                  <strong>RESTRICTED ACTIVITIES</strong>
                </span>
              </p>
              <p>&nbsp;</p>
              <p className="western" align="justify">
                <span>In connection with your use of our websites, your DartPay Account or in the course of your interactions with us, other customers, or third parties, you must not:</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p align="justify">
                &bull;&nbsp;<span>Breach the Terms between you and us;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Violate any law, statute, ordinance, or regulations;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Infringe our or any third party's copyright, patent, trademark, trade secret or other intellectual property rights, or rights of publicity or privacy;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Create or control more than one personal account for yourself without our express authorization, through among other methods, using a name that is not yours, using a temporary email address or phone number, or providing any other falsified personal information;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Act in a manner that is defamatory, trade libelous, threatening or harassing;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Provide false, inaccurate or misleading information;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Send or receive what we reasonably believe to be potentially fraudulent money or payments for advertising, marketing, or otherwise on an unsolicited and unauthorized basis;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Refuse to cooperate in an investigation or provide confirmation of your identity or any information you provide to us;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Control an account that is linked to another DartPay Account that has engaged in any of these restricted activities;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Use the DartPay services in a manner that results in or may result in:</span>
              </p>
              <ul>
                <li>
                  <span>Complaints;</span>
                </li>
                <li>
                  <span>Disputes; claims, reversals, chargebacks,</span>
                </li>
                <li>
                  <span>Fees, fines, penalties or other liability or losses to DartPay, other customers, third parties or you;</span>
                </li>
              </ul>
              <p></p>
              <p align="justify">
                &bull;&nbsp;<span>Use your DartPay Account in a manner that we, Visa, Mastercard, NIBSS or any other electronic funds transfer network reasonably believes to be an abuse of the transfer system or a violation of network rules;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Have any amounts owed to us;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Take any action that imposes an unreasonable or disproportionately large load on our websites, software, systems (including any networks and servers used to provide any of the services) operated by us or on our behalf;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Facilitate any viruses, trojan horses, malware, worms or other computer programming routines that attempts to or may damage, disrupt, corrupt, misuse, detrimentally interfere with, surreptitiously intercept or expropriate, or gain unauthorized access to any system, data or information;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Use an anonymizing proxy; use any robot, spider, other automatic device, or manual process to monitor or copy our websites without our prior written permission;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Interfere or disrupt or attempt to interfere with or disrupt our websites, software, systems (including any networks and servers used to provide any of the DartPay services) operated by us or on our behalf;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Take any action that may cause us to lose any of the services from our Internet service providers, payment processors, or other suppliers or service providers;</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Circumvent any of our policies or determinations about your DartPay Account such as temporary or indefinite suspensions or other account holds, limitations or restrictions, including, but not limited to, engaging in the following actions: attempting to open new or additional DartPay Account(s) when you have amounts owed to us or when your DartPay Account has been restricted, suspended or otherwise limited; opening new or additional DartPay Accounts using information that is not your own (e.g. name, address, email address, etc.); or using someone else's DartPay Account; or</span>
              </p>
              <p align="justify">
                &bull;&nbsp;<span>Harass and/or threaten our employees, agents, or other users.</span>
              </p>
              <p align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Actions We May Take if You Engage in Any Restricted Activities</strong>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>If we believe that you've engaged in any of these activities, we may take a number of actions to protect ourselves, our customers and others at any time in our sole discretion. The actions we make take include, but are not limited to, the following:</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Terminate your agreement with us, limit your DartPay Account, and/or close or suspend your DartPay Account immediately and without penalty to us;</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Refuse to provide the DartPay services to you in the future;</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Limit your access to our websites, software, systems (including any networks and servers used to provide any of the DartPay services) operated by us or on our behalf, your DartPay Account, including limiting your ability to </span>
                <span>
                  <span lang="en-US">receive </span>
                </span>
                <span>money with your DartPay Account or any linked debit card to your DartPay Account; or</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Take legal action against you.</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Recoup any lost funds from activities carried out;</span>
              </p>
              <p>&nbsp;</p>
              <p className="western" align="justify">
                <span>If we close your DartPay Account or terminate your use of the DartPay services for any reason, we will provide you with notice of our actions and make any unrestricted money held in your DartPay Account or that has been sent to you available for bank transfers.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>You are responsible for all reversals, chargebacks, claims, fees, fines, penalties and other liability incurred by us, any customer, or a third party caused by or arising out of your breach of their Terms, and/or your use of the DartPay services</span>
                <span>
                  <span lang="en-US">.</span>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Age Restriction</strong>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>Our website and services are directed to people from the ages of 18 and above. We do not knowingly engage in people younger than the age of 18.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Intellectual Property</strong>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>Unless otherwise stated, DartPay and/or its licensors own the intellectual property rights and materials on the website subject to the license below. All text, formatting (including without limitation the arrangement of materials on the DartPay website and the graphics, animation, tools, commercials, music, video, articles, sound, copy, trade names, logos and other materials and information on the website are subject to the intellectual property rights of DartPay and its affiliates and their licensors and licensees (collectively the "Content"). We do not grant you any right, license, title or interest to any of our intellectual Property rights which you may or may not have access to. These Content may not be copied, reverse engineered, decompiled, disassembles, modified or reposted to other websites. Nothing on the DartPay site should be construed as granting by implication or otherwise, any license or right to use any Trademark displayed on the DartPay website without the written permission of DartPay or such third party that may own the Trademark. You agree to take such actions including any legal or official document or other documents that may be needed to further affirm our intellectual property rights.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>License to Use Our Website</strong>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>We grant you a non-assignable, non-exclusive and revocable license to use the software provided as part of our services in the manner permitted by these Terms. This license grant includes all updates, upgrades, new versions and replacement software for you to use in connection with our services.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>You may not:</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Modify or copy the materials;</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Remove any copyright or other proprietary notations from the materials; or</span>
              </p>
              <p className="western" align="justify">
                &bull;&nbsp;<span>Transfer the materials to another person or "mirror" the materials on any other server.</span>
              </p>
              <p>&nbsp;</p>
              <p className="western" align="justify">
                <span>This license shall automatically terminate if you violate any of these restrictions and may be terminated by DartPay at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>The services are protected by copyright, trademark, and other laws of both </span>
                <span>
                  <span lang="en-US">the Federal Republic of Nigeria</span>
                </span>
                <span> and foreign countries. Nothing in this Term gives you a right to use the DartPay name or any of DartPay's trademarks, logos, domain names, and other distinctive brand features. All right, title and interest in and to the services are and will remain the exclusive property of DartPay and its licensors.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>If you do not comply with all the provisions, then you will be liable for all resulting damages suffered by you, DartPay and all third parties. Unless otherwise provided by applicable law, you agree not to alter, re-design, reproduce, adapt, display, distribute, translate, disassemble, reverse engineer, or otherwise attempt to create any source code that is derived from the software.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>Any feedback, comments, or suggestions you may provide to us and our services is entirely voluntary and we will be free to use such feedback, comments or suggestion as we see fit without any obligation to you.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span style={{color: '#003366'}}>
                  <strong>Termination</strong>
                </span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>You may terminate this Agreement by closing your DartPay Account.</span>
              </p>
              <p className="western" align="justify">&nbsp;</p>
              <p className="western" align="justify">
                <span>We may suspend or terminate your account or cease providing you with all or part of the services at any time, including but not limited to, if we reasonably believe; (i) you do not comply with any of the provisions of this Agreement, (ii) you create risk or possible legal exposure to us, (iii) we are required by law to do so, (iv) you have engaged in fraudulent or suspicious transactions.</span>
              </p>
            </div>
          </div>
          <Footer/>
        </div>
     )
}