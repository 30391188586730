import axios from "axios";
import {
  addBankDetailsUrl,
  bankNameUrl,
  cashpointCustomersUrl,
  cashpointUrl,
  changePasswordUrl,
  completeRegUrl,
  contactSupportUrl,
  currentUserUrl,
  loginUrl,
  merchantUrl,
  resetPwrdUrl,
  startSignupUrl,
  banksListUrl,
  cashpointDeactivateUrl,
} from "./urls";

let d = new Date();
let requestId = d.getTime();
let publicKey = process.env.REACT_APP_PUBLIC_KEY;
let accessToken = sessionStorage.getItem("v_pay_token");

axios.defaults.headers["publicKey"] = publicKey;
axios.defaults.headers["requestId"] = requestId;
if (accessToken) {
  axios.defaults.headers.accessToken = accessToken;
  axios.defaults.headers["b-access-token"] = accessToken;
}

export const startSignup = (data) => {
  return axios({
    method: "POST",
    url: startSignupUrl,
    data,
  });
};

export const completeSignup = (data) => {
  return axios({
    method: "POST",
    url: completeRegUrl,
    data,
  });
};

export const editMerchant = (data, id) => {
  return axios({
    method: "POST",
    url: `${merchantUrl}${id}/edit`,
    data,
  });
};

export const fetchMerchantBalance = (id) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}${id}/wallet/balance`,
  });
};

export const fetchMerchantPayouts = (id) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}${id}/payouts/show`,
  });
};

export const merchantLogin = (data) => {
  return axios({
    method: "POST",
    url: loginUrl,
    data,
  });
};

export const getCurrentUser = () => {
  return axios({
    method: "GET",
    url: currentUserUrl,
  });
};

export const getMerchants = (id) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}${id}/show`,
  });
};

export const fetchMerchantCustomers = (id) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}${id}/customers/show`,
  });
};

export const fetchMerchantTransactions = (id) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}${id}/transactions/show`,
  });
};

export const fetchMerchantCashpoints = (id) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}${id}/cashpoints/show`,
  });
};

export const fetchMerchant = (id) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}${id}/show`,
  });
};

export const fetchMerchantKey = (id) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}${id}/privatekey`,
  });
};

export const addCashpoint = (data) => {
  return axios({
    method: "POST",
    url: cashpointUrl,
    data,
  });
};

export const fetchMerchantCustomersCashpoints = (id, data) => {
  return axios({
    method: "POST",
    url: `${cashpointCustomersUrl}${id}/customers/show`,
    data,
  });
};

export const contactSupport = (data) => {
  return axios({
    method: "POST",
    url: contactSupportUrl,
    data,
  });
};

export const changePassword = (data) => {
  return axios({
    method: "POST",
    url: changePasswordUrl,
    data,
  });
};

export const resetPassword = (data) => {
  return axios({
    method: "POST",
    url: resetPwrdUrl,
    data,
  });
};

export const verifyBankDetails = (data) => {
  return axios({
    method: "POST",
    url: bankNameUrl,
    data,
  });
};

export const addBankDetails = (data) => {
  return axios({
    method: "POST",
    url: addBankDetailsUrl,
    data,
  });
};

export const deactivateCashpoint = (data) => {
  return axios({
    method: "POST",
    url: cashpointDeactivateUrl,
    data,
  });
};

export const fetchBanks = (data) => {
  return axios({
    method: "GET",
    url: banksListUrl,
  });
};
export const requeryTransaction = (key, ref) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}wallet/requery/transaction/${ref}`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const fetchBeneficiaries = (key) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}wallet/beneficiary/show`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};
export const walletWithdraw = (key, data) => {
  return axios({
    method: "POST",
    url: `${merchantUrl}wallet/withdraw`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};
export const walletTransfer = (key, data) => {
  return axios({
    method: "POST",
    url: `${merchantUrl}wallet/transfer`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};
export const addBeneficiary = (key, data) => {
  return axios({
    method: "POST",
    url: `${merchantUrl}wallet/beneficiary/add`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const fetchCodes = (key, type) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}wallet/transfer/${type}/codes/show`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const fetchTranferFees = (key, id) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}wallet/transfer/fees/${id}`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};
export const fetchWalletPrefix = (key, id) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}wallet/prefix/${id}`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};
export const toggleFundDestination = (id) => {
  return axios({
    method: "GET",
    url: `${merchantUrl}${id}/funddestination`,
  });
};
